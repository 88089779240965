import {gql} from "@apollo/client"
export const GET_MEDIA_LIST = gql`
  query MediaList($skip: Int) {
    MediaList(skip: $skip) {
      edges {
        cursor
        node {
          id
          fileName
          filePath
          fileFolder
          fileSize
          fileType
          fileExt
          createdAt
          user
          userId
          tag
          product
          product_variantion_photo
          mediaImageGenerateds
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        count
      }
    }
  }
`
