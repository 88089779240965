import {gql} from "@apollo/client"

export const ADD_TAG = gql`
  mutation AddTag($name: String!, $code: String, $description: String, $order: Int, $groupId: Int!, $thumbnailId: Int, $parentsId: JSON) {
    AddTag(name: $name, code: $code, description: $description, order: $order, groupId: $groupId, thumbnailId: $thumbnailId, parentsId: $parentsId)
  }
`
export const UPDATE_TAG = gql`
  mutation UpdateTag(
    $id: Int!
    $name: String!
    $code: String
    $description: String
    $order: Int
    $groupId: Int!
    $thumbnailId: Int
    $parentsId: JSON
  ) {
    UpdateTag(
      id: $id
      name: $name
      code: $code
      description: $description
      order: $order
      groupId: $groupId
      thumbnailId: $thumbnailId
      parentsId: $parentsId
    )
  }
`
export const DELETE_TAG = gql`
  mutation DeleteTag($id: Int!) {
    DeleteTag(id: $id)
  }
`
