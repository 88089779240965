import {Fragment, useEffect, useState} from "react"
import {useForm} from "react-hook-form"
import {GetAvatarUrl} from "../../../utils/getAvatarUrl"
import ProductVariantionsForm from "./ProductVariantionsForm"
import Select from "react-select"
import AsyncSelect from "react-select/async"
import {useLazyQuery} from "@apollo/client"
import {GET_PRODUCT_LIST} from "../graphql/query"

export default function ProductForm({onSubmit, defaultData}) {
  const [GetProductListForRelated, {loading, fetchMore, refetch}] = useLazyQuery(GET_PRODUCT_LIST)

  const {
    register,
    setValue,
    handleSubmit,
    formState: {errors},
  } = useForm({
    defaultValues: defaultData,
  })

  let defaultVars


  if (defaultData && defaultData?.variantions) {
    defaultVars = [...defaultData?.variantions]
    defaultVars = defaultVars.map((v) => {
      const copyTags = [...v.tags]
      let formatedTags = copyTags.map((i) => i.tagId)
      return {...v, tags: formatedTags}
    })
  }


  const [variantions, setVariantions] = useState(defaultVars || [])
  const [thumbnail, setThumbnail] = useState()

  function submitOnForm(data) {
    data.variantions = variantions
    data.thumbnailId = thumbnail?.id
    onSubmit(data)
  }

  const addVariantion = () => {
    setVariantions([
      ...variantions,
      {
        main_price: "",
        wholesale_price: "",
        tags: [],
        photos: [],
      },
    ])
  }
  const removeVariantion = (index) => {
    if (index > -1) {
      setVariantions((variantions) => variantions.filter((s, i) => i !== index))
    }
  }
  const updateVariantion = (index, field, value) => {
    if (index > -1) {
      setVariantions((variantions) =>
        variantions.map((data, i) => {
         
          if (i !== index) return data
          let tags = data.tags,
            photos = data.photos
          // console.log("before photos ", photos)

          if (field === "add_tag") {
            tags.push(value)
          } else if (field === "remove_tag") {
            tags = tags.filter((s) => s !== value)
          } else if (field === "add_photos") {
            console.log("add photos", value)
            // value is array
            photos = photos.concat(value)
          } else if (field === "remove_photo") {
            // value is photo id
            photos = photos.filter((s) => s.id !== value)
          } else if (field === "featured_photo") {
            // value is photo id
            photos = photos.map((s) => {
              if (s.id === value) {
                s.isThumbnail = true
              } else {
                s.isThumbnail = false
              }
              return s
            })
          }
          console.log('data1 ==============\n', data);
          return {
            id: data.id,
            main_price: field === "main_price" ? value : data.main_price,
            wholesale_price: field === "wholesale_price" ? value : data.wholesale_price,
            tags: tags,
            photos: photos,
          }
        })
      )
    }
  }

  const searchProductsForRelated = async (s) => {
    const response = await GetProductListForRelated({
      variables: {
        search: s,
      },
    })
    if (response?.data?.GetProductListResponse?.edges?.length > 0) {
      return response?.data?.GetProductListResponse?.edges.map((e) => {
        return {
          value: e.node.id,
          label: e.node.code || e.node.name,
        }
      })
    } else {
      return []
    }
  }
  const handleChangeRelated = async (newValue, action) => {
    setValue(
      "related",
      newValue.map((d) => d.value)
    )

    switch (action.action) {
      case "select-option": {
        // update(index, "add_tag", action.option.value)
        break
      }
      case "remove-value": {
        // update(index, "remove_tag", action.removedValue.value)
        break
      }
      // default: {
      //   onChangePost("categoryIds", [...value.filter((o) => o.value !== action.removedValue.value)])
      //   break
      // }
    }
  }
  // console.log("defaultData: ", defaultData)
  // console.log("variantions: ", variantions)
  useEffect(() => {
    if (defaultData) {
      variantions.forEach((v) => {
        v.photos.forEach((photo) => {
          if (photo.mediaId === defaultData.thumbnailId) {
            photo.id = photo.mediaId
            setThumbnail(photo)
          }
        })
      })
    }

    return () => {}
  }, [defaultData])

  // console.log("defaultData:", defaultData)

  return (
    <>
      <form onSubmit={handleSubmit(submitOnForm)}>
        <div className="row">
          {/* status */}
          <div className="col-sm-12 mb-3">
            <label className="form-label">Status</label>
            <select defaultValue={defaultData?.status || "ACTIVE"} className="form-control" {...register("status", {required: true})}>
              <option value="ACTIVE">ACTIVE</option>
              <option value="INACTIVE">INACTIVE</option>
            </select>
          </div>
          {/* name */}
          <div className="col-sm-12 mb-3">
            <label className="form-label">Name</label>
            <input type="text" className="form-control" {...register("name", {required: true})} />
            {errors.name && <div className="">Title is required.</div>}
          </div>
          {/* code */}
          <div className="col-sm-12 mb-3">
            <label className="form-label">Code</label>
            <input type="text" className="form-control" {...register("code")} />
          </div>
          {/* gauge */}
          <div className="col-sm-12 mb-3">
            <label className="form-label">Gauge</label>
            <input type="text" className="form-control" {...register("gauge")} />
          </div>
          {/* Ply */}
          <div className="col-sm-12 mb-3">
            <label className="form-label">Ply</label>
            <input type="text" className="form-control" {...register("ply")} />
          </div>
          {/* Stitch */}
          <div className="col-sm-12 mb-3">
            <label className="form-label">Stitch</label>
            <input type="text" className="form-control" {...register("stitch")} />
          </div>
          {/* Size */}
          <div className="col-sm-12 mb-3">
            <label className="form-label">Size</label>
            <input type="text" className="form-control" {...register("size")} />
          </div>
          {/* Fit */}
          <div className="col-sm-12 mb-3">
            <label className="form-label">Fit</label>
            <input type="text" className="form-control" {...register("fit")} />
          </div>
          {/* Description */}
          <div className="col-sm-12 mb-3">
            <label className="form-label">Description</label>
            <textarea className="form-control" {...register("description")} />
          </div>
          {/* thumbnail */}
          <div className="col-sm-12 mb-3">
            <label className="form-label">Default thumbnail</label>
            <div className="product-thumb21-wrap">
              {variantions.map((v, key) => {
                return v.photos.map((photo, k) => {
                  if (photo.mediaId) photo.id = photo.mediaId
                  return (
                    <div key={k} className={`product-thumb me-2 ${photo.id === thumbnail?.id ? "featured" : ""}`} onClick={() => setThumbnail(photo)}>
                      <img src={GetAvatarUrl(photo.mediaImageGenerateds, "PRODUCT_MEDIUM")} alt="" />
                    </div>
                  )
                })
              })}
            </div>
          </div>

          <div className="col-sm-12 mb-3">
            <label className="form-label">Related products</label>
            <AsyncSelect
              // defaultValue={[{value: 1494, label: '39030'}]}
              defaultValue={defaultData?.product.map((rp) => {
                return {value: rp.productRelated.id, label: rp.productRelated.code || rp.productRelated.name}
              })}
              isMulti
              name="related"
              className="basic-multi-select"
              classNamePrefix="select"
              loadOptions={searchProductsForRelated}
              onChange={handleChangeRelated}
            />
          </div>

          {/* Variantions */}
          <div className="col-sm-12 mb-3">
            <div className="d-flex justify-content-between">
              <label className="form-label">Variantions</label>
              <button type="button" className="fs-13 btn-link" onClick={addVariantion}>
                Add variantion
              </button>
            </div>
            {/* list */}
            <div className="">
              {variantions.map((d, key) => {
                return (
                  <Fragment key={key}>
                    <ProductVariantionsForm
                      // dd
                      data={d}
                      index={key}
                      remove={removeVariantion}
                      update={updateVariantion}
                    />
                  </Fragment>
                )
              })}
            </div>
          </div>

          {/* <div className="col-sm-12">
            <div>
              <label htmlFor="description" className="form-label">
                Нүүр зураг
              </label>
              {featured && (
                <div className="photo-thumb">
                  <img src={GetAvatarUrl(featured.mediaImageGenerateds, "PRODUCT_MEDIUM")} alt="" />
                </div>
              )}
            </div>

            <label htmlFor="description" className="form-label">
              Зурагууд
            </label>
            <div className="d-flex">
              {images.map((image, key) => {
                return (
                  <div key={key} className="photo-thumb" onClick={() => setFeatured(image)}>
                    <img src={GetAvatarUrl(image.mediaImageGenerateds, "PRODUCT_MEDIUM")} alt="" />
                  </div>
                )
              })}
            </div>
          </div> */}
        </div>

        <hr className="my-4" />
        <div className="d-flex justify-content-between">
          <button className="btn btn-primary btn-lg" type="submit">
            {defaultData ? "Update product" : "Create product"}
          </button>
          {/* {defaultData && (
            <button className="btn btn-danger btn-lg" type="button" onClick={Delete}>
              Delete
            </button>
          )} */}
        </div>
      </form>
    </>
  )
}
