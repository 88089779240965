import {Link} from "react-router-dom"
import Modal from "../../components/Modal"
import {useModal} from "../../providers/Modal"
import DownloadRequestExcel from "./DownloadRequestExcel"
import {useState} from "react"
import {useMutation} from "@apollo/client"
import {DELETE_REQUEST, UPDATE_REQUEST_STATUS} from "./graphql/mutation"
import SpinLoader from "../../components/loaders/SpinLoader"

export default function PriceQuotationRowItem({request, refetch}) {
  const [status, setStatus] = useState(request.status)
  const {modalId, showModal} = useModal()
  const [UpdateStatus, {loading: updateLoading}] = useMutation(UPDATE_REQUEST_STATUS)
  const [DeleteRequest, {loading: deleteLoading}] = useMutation(DELETE_REQUEST)
  return (
    <>
      <tr>
        <td className="ps-2">
          {request.status === "pending" && <span className="badge text-bg-warning">Хүлээгдэж байгаа</span>}
          {request.status === "active" && <span className="badge text-bg-primary">Идэвхитэй</span>}
          {request.status === "completed" && <span className="badge text-bg-success">Амжилттай дууссан</span>}
        </td>
        <td className="">
          {/* {request.user.profile.firstname} {request.user.profile.lastname} */}
          <h6 className="font-medium mb-0">
            {request.user.profile.firstname} .{request.user.profile.lastname?.slice(0, 1).toUpperCase()}
          </h6>
          <span className="text-muted">Дэлгүүр: {request.user.profile.numberOfStores || 0}</span>
        </td>
        <td className="">{request.products?.length}</td>
        <td className="">{new Date(parseInt(request.createdAt)).toLocaleString()}</td>
        <td className=" ">
          <div className="d-flex align-items-center justify-content-end">
            <DownloadRequestExcel id={request.id} />
            <Link to={`#_`} onClick={() => showModal(`prEditModal${request.id}`)} className="ms-2 btn btn-sm btn-light">
              Засах
            </Link>
          </div>
        </td>
      </tr>
      <Modal id={`prEditModal${request.id}`}>
        <Modal.Body>
          <SpinLoader loading={updateLoading || deleteLoading} absolute />
          <h4>Засах</h4>
          <div className="form-group  mb-4">
            <label htmlFor="">Төлөв</label>
            <select
              className="form-select"
              onChange={(e) => {
                setStatus(e.target.value)
              }}
              defaultValue={status}
            >
              <option value="">Бүх төлөв</option>
              <option value="pending">Хүлээгдэж байгаа</option>
              <option value="active">Идэвхитэй</option>
              <option value="completed">Амжилттай дууссан</option>
            </select>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center justify-content-start">
              <button
                className="btn btn-dark"
                onClick={() => {
                  UpdateStatus({
                    variables: {
                      status: status,
                      id: request.id,
                    },
                    onCompleted: () => {
                      showModal("")
                      refetch()
                    },
                  })
                }}
              >
                Хадгалах
              </button>
              <button className="btn btn-light ms-2" onClick={() => showModal("")}>
                Болих
              </button>
            </div>
            <button
              className="btn btn-danger"
              onClick={() => {
                DeleteRequest({
                  variables: {
                    id: request.id,
                  },
                  onCompleted: () => {
                    showModal("")
                    refetch()
                  },
                })
              }}
            >
              Устгах
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
