import {gql} from "@apollo/client"

export const ADD_TERM = gql`
  mutation AddTerm($title: String!, $content: String!) {
    AddTerm(title: $title, content: $content)
  }
`
export const UPDATE_TERM = gql`
  mutation UpdateTerm($id: Int!, $title: String!, $content: String!) {
    UpdateTerm(id: $id, title: $title, content: $content)
  }
`
export const DELETE_TERM = gql`
  mutation DeleteTerm($id: Int!) {
    DeleteTerm(id: $id)
  }
`
