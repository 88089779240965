import {useMutation, useQuery} from "@apollo/client"
import {useForm} from "react-hook-form"
import {CREATE_PRODUCT, DELETE_PRODUCT, UPDATE_PRODUCT} from "./graphql/mutation"
import Cookies from "universal-cookie"
import axios from "axios"
import {useRef, useState} from "react"
import {GetAvatarUrl} from "../../utils/getAvatarUrl"
import SpinLoader from "../../components/loaders/SpinLoader"
import {toast} from "react-hot-toast"
import {Link, useNavigate, useParams} from "react-router-dom"
import ProductForm from "./form/ProductForm"
import {GET_PRODUCT_DETAIL} from "./graphql/query"
import {useModal} from "../../providers/Modal"
import Modal from "../../components/Modal"
const cookies = new Cookies()

export default function EditProductScreen() {
  const params = useParams()
  const navigate = useNavigate()
  const {modalId, showModal} = useModal()
  const [UpdateProduct, {loading: updateLoading}] = useMutation(UPDATE_PRODUCT, {
    refetchQueries: ["GetProductDetail"],
  })
  const [DeleteProduct, {loading: deleteLoading}] = useMutation(DELETE_PRODUCT)
  const {data, loading} = useQuery(GET_PRODUCT_DETAIL, {
    variables: {
      id: parseInt(params.id),
    },
    fetchPolicy: "no-cache",
  })

  const onSubmit = async (data) => {
    const response = await UpdateProduct({
      variables: {id: parseInt(params.id), ...data},
      onCompleted: (d) => {
        if (d?.UpdateProduct) toast.success("Хадгалагдлаа")
      },
    })
  }

  return (
    <div className="">
      <div className="gh-canvas-header">
        <div className="gh-canvas-header-content">
          <div className="flex flex-column">
            <div className="gh-canvas-breadcrumb">
              <Link to="/products">Бүтээгдэхүүн</Link>
              <svg viewBox="0 0 18 27">
                <path
                  d="M2.397 25.426l13.143-11.5-13.143-11.5"
                  strokeWidth="3"
                  stroke="#0B0B0A"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
              Засах
            </div>
            <h2 className="gh-canvas-title">
              <a href="/products" className="active ">
                Бүтээгдэхүүн засах
              </a>
            </h2>
          </div>
          <div className="view-actions">
            {data?.GetProductDetail?.id && (
              <button onClick={() => showModal(`deleteProductModal${data?.GetProductDetail?.id}`)} type="button" className="btn  btn-danger">
                Устгах
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="">
        <div className="row g-5 position-relative">
          {(loading || updateLoading || deleteLoading) && (
            <div className="pos-abs filled h-100 bg-loader">
              <SpinLoader loading={true} />
            </div>
          )}
          <div className="col-md-7 col-lg-8 ">{!loading && data && <ProductForm onSubmit={onSubmit} defaultData={data.GetProductDetail} />}</div>
        </div>
      </div>
      {data?.GetProductDetail && (
        <>
          <Modal id={`deleteProductModal${data?.GetProductDetail?.id}`}>
            <Modal.Body>
              <SpinLoader loading={deleteLoading} absolute />
              <h4>Устгах уу?</h4>

              <div className="d-flex align-items-center justify-content-between mt-4">
                <div className="d-flex align-items-center justify-content-start">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      DeleteProduct({
                        variables: {
                          id: data.GetProductDetail.id,
                        },
                        onCompleted: () => {
                          toast.success("Устгагдлаа")
                          showModal("")
                          navigate("/products")
                        },
                      })
                    }}
                  >
                    Устгах
                  </button>
                  <button className="btn btn-light ms-2" onClick={() => showModal("")}>
                    Болих
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </div>
  )
}
