import {useMutation, useQuery} from "@apollo/client"
import {DELETE_TAG, UPDATE_TAG} from "./graphql/mutation"
import Cookies from "universal-cookie"
import SpinLoader from "../../components/loaders/SpinLoader"
import {toast} from "react-hot-toast"
import {Link, useNavigate, useParams} from "react-router-dom"
import {GET_TAG} from "./graphql/query"
import {useInit} from "../../providers/Init"
import TagForm from "./form/TagForm"
import Modal from "../../components/Modal"
import {useModal} from "../../providers/Modal"
const cookies = new Cookies()

export default function EditTagScreen() {
  const {refetchGroup} = useInit()
  const params = useParams()
  const navigate = useNavigate()
  const {modalId, showModal} = useModal()
  const [UpdateTag, {loading: updateLoading}] = useMutation(UPDATE_TAG)
  const [DeleteTag, {loading: deleteLoading}] = useMutation(DELETE_TAG)

  const {data, loading} = useQuery(GET_TAG, {
    variables: {
      id: parseInt(params.id),
    },
    fetchPolicy: "no-cache",
  })

  const submitForm = async (values) => {
    values.id = parseInt(params.id)
    values.order = values.order ? parseInt(values.order) : 0
    values.groupId = parseInt(values.groupId)
    if (!values.thumbnailId) {
      delete values.thumbnailId
    }

    await UpdateTag({
      variables: values,
      onCompleted: (data) => {
        toast.success("Хадгалдлаа")
        refetchGroup()
      },
    })
  }

  return (
    <div className="">
      <div className="gh-canvas-header">
        <div className="gh-canvas-header-content">
          <div className="flex flex-column">
            <div className="gh-canvas-breadcrumb">
              <Link to="/tags">Таггууд</Link>
              <svg viewBox="0 0 18 27">
                <path
                  d="M2.397 25.426l13.143-11.5-13.143-11.5"
                  strokeWidth="3"
                  stroke="#0B0B0A"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
              Засах
            </div>
            <h2 className="gh-canvas-title">
              <a href="/products" className="active ">
                Бүтээгдэхүүн засах
              </a>
            </h2>
          </div>
          <div className="view-actions">
            {data?.GetTag?.id && (
              <button onClick={() => showModal(`deleteTagModal${data?.GetTag?.id}`)} type="button" className="btn  btn-danger">
                Устгах
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="position-relative">
        <SpinLoader loading={loading || updateLoading} absolute small />
        {data?.GetTag && <TagForm submitForm={submitForm} tag={data.GetTag} />}
      </div>
      {data?.GetTag && (
        <>
          <Modal id={`deleteTagModal${data?.GetTag?.id}`}>
            <Modal.Body>
              <SpinLoader loading={deleteLoading} absolute />
              <h4>Устгах уу?</h4>

              <div className="d-flex align-items-center justify-content-between mt-4">
                <div className="d-flex align-items-center justify-content-start">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      DeleteTag({
                        variables: {
                          id: data.GetTag.id,
                        },
                        onCompleted: () => {
                          toast.success("Устгагдлаа")
                          showModal("")
                          refetchGroup()
                          navigate("/tags")
                        },
                      })
                    }}
                  >
                    Устгах
                  </button>
                  <button className="btn btn-light ms-2" onClick={() => showModal("")}>
                    Болих
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </div>
  )
}
