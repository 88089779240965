import {Fragment, useEffect, useRef, useState} from "react"
import {useForm} from "react-hook-form"
import Cookies from "universal-cookie"
import axios from "axios"

import {useInit} from "../../../providers/Init"
import ProductVariantionsForm from "./ProductVariantionsForm"
import {GetAvatarUrl} from "../../../utils/getAvatarUrl"
import {logDOM} from "@testing-library/react"
import MediaChooserFromLibrary from "../../media/MediaChooserFromLibrary"

const cookies = new Cookies()

export default function VariantionPhotosForm({index, update, photos}) {
  const {groups} = useInit()

  const [featured, setFeatured] = useState()
  const [file, setFile] = useState()
  const inputRef = useRef(null)

  const handleUploadClick = () => {
    inputRef.current?.click()
  }

  const handleFileChange = async (e) => {
    if (!e.target.files) {
      return
    }
    const response = await axios.post(`${process.env.REACT_APP_IMAGE_UPLOAD_URL}?purpose=product`, Array.from(e.target.files), {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: cookies.get("token"),
      },
    })

    if (response?.data?.files?.length > 0) {
      // setImages(images.concat(response.data.files))

      update(index, "add_photos", response.data.files)
    }
  }
  const removePhoto = (id) => {
    // setImages(images.filter((i) => i.id !== id))
    update(index, "remove_photo", id)
  }
  const setFeaturedPhoto = (id) => {
    // setImages(images.filter((i) => i.id !== id))
    update(index, "featured_photo", id)
  }

  const setMediaFromLibrary = (med) => {
    update(
      index,
      "add_photos",
      med.map((m) => {
        return {
          id: m.id,
          mediaImageGenerateds: m.mediaImageGenerateds,
        }
      })
    )
  }
  // console.log("photos: ", photos)

  return (
    <>
      <div className="card mb-4">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center pb-3">
            <label htmlFor="description" className="form-label mb-0">
              Зурагууд
            </label>
            <div className="">
              <MediaChooserFromLibrary setMedia={setMediaFromLibrary} multiple />
              <a href="#_" onClick={handleUploadClick} className="fs-13">
                {file ? `${file.name}` : "Upload photo"}
              </a>

              <input type="file" ref={inputRef} onChange={handleFileChange} multiple style={{display: "none"}} />
            </div>
          </div>
          <div className="d-flex flex-wrap">
            {photos?.map((photo, key) => {
              return (
                <div className="photo-thumb-wrap my-1" key={key}>
                  <div className="thumb-remove" onClick={() => removePhoto(photo.id)}>
                    X
                  </div>
                  <div className={`photo-thumb me-2 ${photo.isThumbnail ? "featured" : ""}`} onClick={() => setFeaturedPhoto(photo.id)}>
                    <img src={GetAvatarUrl(photo.mediaImageGenerateds, "PRODUCT_MEDIUM")} alt="" />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}
