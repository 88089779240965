import {gql} from "@apollo/client"
export const GET_USER_LIST = gql`
  query UserList($type: String, $skip: Int, $search: String, $currencyId: String, $priceGroupId: Int, $show_price: String) {
    UserList(type: $type, skip: $skip, search: $search, currencyId: $currencyId, priceGroupId: $priceGroupId, show_price: $show_price) {
      edges {
        node {
          id
          email
          status
          createdAt
          profile {
            firstname
            lastname
            businessName
            phone
            clientDescription
            businessDescription
            numberOfStores
            specialInstructions
            shippingAddress
            billingAddress
            website
          }
          user_price_option {
            id
            currencyId
            show_price
            userId
            priceGroupId
            price_group {
              id
              name
              sale
              currencyId
              exchange_price
            }
            currency {
              id
            }
          }
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
        count
      }
    }
  }
`
export const GET_ADMIN = gql`
  query GetAdmin($id: Int!) {
    GetAdmin(id: $id) {
      id
      email
      status
      createdAt
      profile {
        firstname
        lastname
        businessName
        phone
        clientDescription
        businessDescription
        numberOfStores
        specialInstructions
        shippingAddress
        billingAddress
        website
      }
    }
  }
`
export const GET_USER = gql`
  query GetUser($id: Int!) {
    GetUser(id: $id) {
      id
      email
      status
      createdAt
      profile {
        firstname
        lastname
        businessName
        phone
        clientDescription
        businessDescription
        numberOfStores
        specialInstructions
        shippingAddress
        billingAddress
        website
      }
    }
  }
`
