import React from "react"
import LoginForm from "./LoginForm"
import Logo from "../../assets/images/logo.png"

export default function LoginScreen() {
  return (
    <div className="vh-100 vw-100 ">
      <div className="d-flex flex-column align-items-center justify-content-center" style={{paddingTop: "10%"}}>
        <img src={Logo} alt="" style={{width: "180px", marginBottom: "40px"}} />
        <div style={{maxWidth: "540px"}}>
          {/* <h1 className="page-title text-center ">My account</h1> */}
          <p className=" text-center">Contact sales@munkhcashmere.mn for Trade account enquiries. With trade account, you can available to see the all of our collections and products.</p>
          <div className="mt-5" style={{padding: "0 80px"}}>
            <LoginForm />
          </div>
        </div>
      </div>
    </div>
  )
}
