export default function NotFound() {
  return (
    <div className="page-wrapper">
      <div className="container">
        <div className="d-flex flex-column justify-content-center" style={{minHeight: "calc(100vh - 50px)"}}>
          <h3>Oops! An Error Occurred</h3>
          <p>The server returned a "404 Not Found".</p>
        </div>
      </div>
    </div>
  )
}
