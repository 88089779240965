import React from "react"
import {useQuery} from "@apollo/client"

import {Link} from "react-router-dom"
import {GetAvatarUrl} from "../../utils/getAvatarUrl"
import {GET_USER_LIST} from "./graphql/query"

export default function AdminsScreen() {
  const [list, setList] = React.useState([])
  const [pageInfo, setPageInfo] = React.useState()
  const {loading, fetchMore} = useQuery(GET_USER_LIST, {
    fetchPolicy: "network-only",
    variables: {
      type: "admin",
    },
    onCompleted: (data) => {
      setList(data.UserList.edges.map((edge) => edge.node))

      setPageInfo(data.UserList.pageInfo)
    },
  })

  return (
    <div className="page-wrapper">
      <div className="gh-canvas-header">
        <div className="gh-canvas-header-content">
          <div className="flex flex-column">
            <h2 className="gh-canvas-title">
              <a href="/admins" className="active ">
                Админ
              </a>
            </h2>
          </div>
          <div className="view-actions">
            <Link to="/admins/add" type="button" className="btn  btn-dark">
              Админ нэмэх
            </Link>
          </div>
        </div>
      </div>
      <div className="">
        <div className="table-responsive">
          <table className="table align-middle mb-0 bg-white">
            <thead className="bg-light">
              <tr>
                <th scope="col" className="">
                  Э-мэйл
                </th>
                <th scope="col" className="">
                  Нэр
                </th>
                <th scope="col" className="">
                  Овог
                </th>
                <th scope="col" className="">
                  Огноо
                </th>
                <th scope="col" className=""></th>
              </tr>
            </thead>
            <tbody>
              {list.map((user, key) => {
                return (
                  <tr key={key}>
                    <td className="px-4">{user.email}</td>
                    <td className="px-4">{user.profile.firstname}</td>
                    <td className="px-4">{user.profile.lastname}</td>
                    <td className="px-4">{new Date(parseInt(user.createdAt)).toLocaleString()}</td>
                    <td className="px-4 d-flex justify-content-end">
                      <Link to={`/admins/edit/${user.id}`} className="btn btn-sm btn-light">
                        Засах
                      </Link>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
