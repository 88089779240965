import {Link} from "react-router-dom"
import {GetAvatarUrl} from "../../../utils/getAvatarUrl"
import ProductListItemVariantionItem from "./ProductListItemVariantionItem"
import {useEffect, useState} from "react"

export default function ProductListItem({item}) {
  // console.log('item ==============\n', item);
  const [data, setData] = useState(item)

  useEffect(() => {
    return () => {
      setData()
    }
  }, [])

  return (
    <div className="product-list-item py-4 ">
      <div className="p-0 ">
        <div className="row">
          <div className="col-lg-5">
            <div className="align-items-start  row">
              <div className="col-auto">
                <div className="candidate-list-images">
                  <Link to={`/products/edit/${item.id}`}>
                    <img src={GetAvatarUrl(item.thumbnail?.mediaImageGenerateds, "PRODUCT_MEDIUM")} className="product-list-thumb" alt="" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="candidate-list-content mt-3 mt-lg-0">
                  {item.status === "ACTIVE" && <span className="text-success me-1 fs-12">{item.status}</span>}
                  {item.status === "INACTIVE" && <span className="text-secondary me-1 fs-12">{item.status}</span>}
                  <h5 className="fs-19 mb-0">
                    <Link to={`/products/edit/${item.id}`} className="primary-link">
                      {item.name || <span className="text-secondary">untitled</span>}
                    </Link>
                  </h5>
                  <p className="text-muted mb-2">{item.code}</p>
                  <ul className="list-inline mb-0 text-muted">
                    {item.ply && <li className="list-inline-item">{item.ply}</li>}
                    {item.fit && <li className="list-inline-item">{item.fit}</li>}
                    {item.gauge && <li className="list-inline-item">{item.gauge}</li>}
                    {item.stitch && <li className="list-inline-item">{item.stitch}</li>}
                    {item.size && <li className="list-inline-item">/ {item.size}</li>}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="list-inline-item">Variantions price</div>
            {data.variantions.map((vi, k) => {
              return <ProductListItemVariantionItem item={vi} key={k} />
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
