exports.isValidHttppath = (httppath) => {
  httppath = httppath.trim()
  if (!httppath.match(/^[0-9A-Za-z\-]+$/)) return false
  // if (/\d/.test(httppath)) return false
  if (httppath.length < 3 || httppath.length > 30) return false
  return true
}
exports.IsValidFacebookProfileUrl = (url) => {
  var pattern = /(?:https?:\/\/)?(?:www\.)?facebook\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*?(\/)?([^/?]*)/

  return pattern.test(url)
}
exports.IsValidYoutubeChannelUrl = (url) => {
  var pattern = /^(https?:\/\/)?((w{3}\.)?)youtube.com\/(?:channel\/[\w.-]*)?$/

  return pattern.test(url)
}
exports.IsValidTwitterProfileUrl = (url) => {
  var pattern = /^(https?:\/\/)?((w{3}\.)?)twitter.com\/(?:[\w.-]*)?$/

  return pattern.test(url)
}
exports.IsValidLinkedinProfileUrl = (url) => {
  var pattern = /^(https?:\/\/)?((w{3}\.)?)linkedin.com\/(?:in\/[\w.-]*)?$/

  return pattern.test(url)
}
exports.IsValidInstagramProfileUrl = (url) => {
  var pattern = /^(https?:\/\/)?((w{3}\.)?)instagram.com\/(?:[\w.-]*)?$/

  return pattern.test(url)
}
exports.IsValidAppStoreUrl = (url) => {
  var pattern = /^(https?:\/\/)?((w{3}\.)?)apps.apple.com\//
  return pattern.test(url)
}
exports.IsValidPlayStoreUrl = (url) => {
  var pattern = /^(https?:\/\/)?((w{3}\.)?)play.google.com\/store\//
  return pattern.test(url)
}

exports.IsValidUrl = (str) => {
  var pattern = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/
  // fragment locator
  return pattern.test(str)
}
// var pattern =
//   /^(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?$/
// ^([A-Za-z]+ )+[A-Za-z]+$
exports.isValidBankAccountName = (name) => {
  name = name.trim()
  if (!name.match(/^([\u0400-\u04FF]*)+(\s[\u0400-\u04FF]*)?$/)) return false
  if (/\d/.test(name)) return false
  return true
}
