import {Fragment, useEffect, useState} from "react"
import {useInit} from "../../../providers/Init"
import {GetAvatarUrl} from "../../../utils/getAvatarUrl"
import {useMutation} from "@apollo/client"
import {UPDATE_VARIANTION_PRICE} from "../graphql/mutation"
import SpinLoader from "../../../components/loaders/SpinLoader"

export default function ProductListItemVariantionItem({item}) {
  const [UpdateVariantionPrice, {loading, data}] = useMutation(UPDATE_VARIANTION_PRICE)
  const {settings} = useInit()
  const [edit, setEdit] = useState(false)
  const tmpArr = []
  settings.price_group.forEach((pg) => {
    const existData = item.product_variantion_price.find((d) => d.currencyId === pg.currencyId && d.priceGroupId === pg.id)

    tmpArr.push({
      currency: pg.currencyId,
      price_group: pg.id,
      main_price: existData?.main_price || 0,
      sale_price: existData?.sale_price || 0,
      variantionId: item.id,
    })
  })

  const [priceField, setPriceField] = useState(tmpArr)

  const onChangePrice = (price, index, type) => {
    if (type == "main") {
      priceField[index].main_price = parseInt(price)
    } else if (type == "sale") {
      priceField[index].sale_price = parseInt(price)
    }

    setPriceField([...priceField])
  }

  const onSubmitPrice = async () => {
    const response = await UpdateVariantionPrice({
      variables: {
        data: priceField,
      },
    })

    setEdit(false)
  }

  useEffect(() => {
    return () => {
      setPriceField()
    }
  }, [])

  // console.log("settings:", settings)
  // console.log("variantion item id:", item.id)
  // console.log("priceField:", priceField)

  if (priceField?.length === 0) return ""

  return (
    <div className="card p-3 pe-5 my-1 w-100">
      <div className="d-flex flex-nowrap align-items-start gap-1">
        <img src={GetAvatarUrl(item.photos[0]?.photo.mediaImageGenerateds, "PRODUCT_MEDIUM")} className=" product-list-thumb small" alt="" />
        <div>
          {!edit && (
            <div className="ms-3">
              {settings?.currency.map((c, k) => {
                return (
                  <div key={k}>
                    <div className="me-2">{c.id}:</div>
                    {settings.price_group.map((pg, k2) => {
                      const cf = priceField.find((f) => f.price_group === pg.id && f.currency === c.id)
                      if (!cf?.main_price) return ""
                      return (
                        <div className=" fs-14 mt-1 me-2" key={k2}>
                          <b>{pg.name}</b>: Price {cf?.main_price} {cf.sale_price > 0 && `, Sale: ${cf.sale_price}`}
                        </div>
                      )
                    })}
                  </div>
                )
              })}

              <a href="#_" className="primary-link d-flex align-items-center" onClick={() => setEdit(true)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="edit-icon ">
                  <g id="vuesax_linear_edit-2" data-name="vuesax/linear/edit-2" transform="translate(-684 -252)">
                    <g id="edit-2">
                      <path id="Vector" d="M9.263,1.439l-8.21,8.69a3.05,3.05,0,0,0-.67,1.43L.013,14.8a1.527,1.527,0,0,0,1.87,1.77l3.22-.55a2.871,2.871,0,0,0,1.39-.75l8.21-8.69c1.42-1.5,2.06-3.21-.15-5.3C12.353-.791,10.683-.061,9.263,1.439Z" transform="translate(687.997 255.7)" fill="none" stroke="#292d32" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                      <path id="Vector-2" data-name="Vector" d="M0,0A6.126,6.126,0,0,0,5.45,5.15" transform="translate(695.89 258.589)" fill="none" stroke="#292d32" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                      <path id="Vector-3" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(684 252)" fill="none" opacity="0" />
                    </g>
                  </g>
                </svg>
                Засах
              </a>
            </div>
          )}

          {edit && (
            <div className="ms-3">
              <SpinLoader loading={loading} absolute />
              {priceField.map((p, k) => {
                const pGroup = settings.price_group.find((pg) => pg.id === p.price_group)

                return (
                  <Fragment key={k}>
                    <label htmlFor="">
                      <b>{p.currency}</b>: {pGroup.name} (ханш үнэ {pGroup.exchange_price} ₮)
                    </label>
                    <form className="row mb-2">
                      {/* <div className="col-auto">
                        <input type="text" readOnly disabled className="form-control currency-form-control" id="" value={p.currency} />
                      </div> */}
                      {/* <div className="col-auto">
                        <input type="text" readOnly disabled className="form-control " id={p.price_group} value={settings.price_group.find((pg) => pg.id === p.price_group).name} />
                      </div> */}
                      <div className="col-auto">
                        <input type="number" className="form-control " defaultValue={p?.main_price} placeholder={`${p.currency} main price`} onChange={(e) => onChangePrice(e.target.value, k, "main")} />
                      </div>
                      {/* <div className="col-auto">
                        <input type="number" className="form-control " defaultValue={p?.sale_price} placeholder={`${p.currency} sale price`} onChange={(e) => onChangePrice(e.target.value, k, "sale")} />
                      </div> */}
                    </form>
                  </Fragment>
                )
              })}
              {/* {settings?.currency.map((c, ck) => {
                return (
                  <Fragment key={ck}>
                    {settings.price_group.map((pg, gk) => {
                      return (
                        <Fragment key={gk}>
                          <form className="row mb-2">
                            <div className="col-auto">
                              <input type="text" readonly disabled className="form-control currency-form-control" id="" value={c.id} />
                            </div>
                            <div className="col-auto">
                              <input type="text" readonly disabled className="form-control " id={pg.name} value={pg.name} />
                            </div>
                            <div className="col-auto">
                              <input type="number" className="form-control" placeholder={`${c.id} price`} />
                            </div>
                          </form>
                        </Fragment>
                      )
                    })}
                    <hr />
                  </Fragment>
                )
              })} */}
              <div className="mt-2 d-flex">
                <button type="submit" className="btn btn-dark mb-3" onClick={onSubmitPrice}>
                  Update price
                </button>
                <button type="button" className="btn btn-light mb-3 ms-2" onClick={() => setEdit(false)}>
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
