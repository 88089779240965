import React from "react"
import {Link} from "react-router-dom"
import {GetAvatarUrl} from "../../utils/getAvatarUrl"
import {useInit} from "../../providers/Init"
import {GET_POST_LIST} from "./graphql/query"
import {useQuery} from "@apollo/client"
import useInfiniteScroll from "../../utils/InfiniteScroll"

export default function PostsScreen() {
  const [list, setList] = React.useState([])
  const [pageInfo, setPageInfo] = React.useState()
  const {loading, fetchMore, refetch} = useQuery(GET_POST_LIST, {
    fetchPolicy: "network-only",
    variables: {},
    onCompleted: (data) => {
      setList(data.GetPostList.edges.map((edge) => edge.node))
      setPageInfo(data.GetPostList.pageInfo)
    },
  })
  const onMore = async () => {
    if (pageInfo.hasNextPage) {
      const moreresult = await fetchMore({
        variables: {
          skip: pageInfo.endCursor,
        },
      })
      setList(list.concat(moreresult.data.GetPostList.edges.map((edge) => edge.node)))
      setPageInfo(moreresult.data.GetPostList.pageInfo)
    }
  }

  const [lastElementRef] = useInfiniteScroll(pageInfo?.hasNextPage ? () => onMore() : () => {}, loading)

  return (
    <div className="">
      <div className="gh-canvas-header">
        <div className="gh-canvas-header-content">
          <div className="flex flex-column">
            <h2 className="gh-canvas-title">
              <a href="/posts" className="active ">
                Нийтлэл
              </a>
            </h2>
          </div>
          <div className="view-actions">
            <Link to="/posts/add" type="button" className="btn  btn-dark">
              Шинэ нийтлэл
            </Link>
          </div>
        </div>
      </div>
      <div className="">
        <hr />

        <div className="table-responsive">
          <table className="table align-middle mb-0 bg-white">
            <thead className="bg-light">
              <tr>
                <th scope="col" className="">
                  Гарчиг
                </th>
                <th scope="col" className=""></th>
              </tr>
            </thead>
            <tbody>
              {list?.map((item, tk) => {
                return (
                  <tr key={tk}>
                    <td className="px-4  w-100">
                      <div className="d-flex align-items-center">
                        {/* <img src={GetAvatarUrl(tg.thumbnail?.mediaImageGenerateds, "PRODUCT_MEDIUM")} className="product-list-thumb me-3" alt="" /> */}
                        <div className="">
                          <p className="fw-bold mb-0 fs-14">{item.title}</p>
                          <p className="text-muted mb-0 fs-13">status: {item.status}</p>
                        </div>
                      </div>
                    </td>
                    <td className="px-4">
                      <Link to={`/posts/edit/${item.id}`} className="btn btn-light btn-sm btn-rounded">
                        Засах
                      </Link>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
      {pageInfo?.hasNextPage && <div className="lastDiv" ref={lastElementRef}></div>}
    </div>
  )
}
