import OrderScreen from "../modules/order/OrderScreen";


export default function Order() {
  return (
    <>
      <OrderScreen />
    </>
  )
}
