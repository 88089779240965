import React, {useRef, useState} from "react"
import {useInit} from "../../providers/Init"
import {useLazyQuery, useMutation} from "@apollo/client"
import {GET_PRODUCT_PHOTO_SORT_LIST} from "./graphql/query"
import {ReactSortable} from "react-sortablejs"
import {GetAvatarUrl} from "../../utils/getAvatarUrl"
import {UPDATE_PRODUCT_PHOTO_SORT} from "./graphql/mutation"
import SpinLoader from "../../components/loaders/SpinLoader"

export default function SortScreen() {
  const {settings, tags, groups} = useInit()

  const [UpdateSortList, {data: updateData, loading: updateLoading}] = useMutation(UPDATE_PRODUCT_PHOTO_SORT)
  const [GetSortList, {data, loading}] = useLazyQuery(GET_PRODUCT_PHOTO_SORT_LIST)
  const [list, setList] = useState([])
  const [tagId, setTagId] = useState()

  // console.log('groups:', groups);
  const onSave = async () => {
    const response = await UpdateSortList({
      variables: {
        tagId: tagId,
        data: list,
      },
    })
    console.log("response:", response)
  }
  const onChangeTag = async (id) => {
    if (!id) {
      setTagId(undefined)
      setList([])
    } else {
      setTagId(parseInt(id))
      const response = await GetSortList({variables: {tagId: parseInt(id)}})
      if (response?.data?.GetProductPhotoSortList) {
        setList(
          response.data.GetProductPhotoSortList.map((s) => {
            return {
              id: s.id,
              url: GetAvatarUrl(s.variantionPhoto.photo.mediaImageGenerateds, "PRODUCT_MEDIUM"),
            }
          })
        )
      }
    }
  }
  console.log("list:", list)

  return (
    <div className="page-wrapper">
      <div className="gh-canvas-header">
        <div className="gh-canvas-header-content">
          <div className="flex flex-column">
            <h2 className="gh-canvas-title">Эрэмбэ</h2>
          </div>
          <div className="view-actions"></div>
        </div>
      </div>

      <div className="">
        <div className="filter-box">
          <div className="row row-cols-lg-auto g-2 align-items-center mb-2">
            <div className="col-12">
              <select className="form-select" onChange={(e) => onChangeTag(e.target.value)}>
                <option value={""}>-</option>
                {groups
                  ?.find((g) => g.slug == "collection")
                  ?.tags?.map((t, k) => (
                    <option key={k} value={t.id}>
                      {t.name}
                    </option>
                  ))}
                {/* {tags
                  .filter((t) => t.type === "CATEGORY")
                  .map((t, k) => (
                    <option key={k} value={t.id}>
                      {t.name}
                    </option>
                  ))} */}
              </select>
            </div>
            {list?.length > 0 && (
              <>
                <div className="col-12">
                  <button className="btn btn-dark" onClick={onSave}>
                    Хадгалах
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        <div>
          <SpinLoader loading={loading || updateLoading} absolute />
          <ReactSortable list={list} setList={setList} className="drag-wrap">
            {list.map((item) => (
              <div key={item.id} className="drag-item">
                <img src={item.url} className="w-100" />
              </div>
            ))}
          </ReactSortable>
        </div>
      </div>
    </div>
  )
}
