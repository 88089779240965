import DashScreen from "../modules/dashboard/DashScreen";


export default function Dashboard() {
  return (
    <>
      <DashScreen />
    </>
  )
}
