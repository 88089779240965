import PriceQuotationScreen from "../modules/PriceQuotation/PriceQuotationScreen";


export default function PriceQuotation() {
  return (
    <>
      <PriceQuotationScreen />
    </>
  )
}
