import {useMutation, useQuery} from "@apollo/client"

import {useRef, useState} from "react"
import {GET_MEDIA_LIST} from "./graphql/query"
import {GetAvatarUrl} from "../../utils/getAvatarUrl"
import axios from "axios"
import Cookies from "universal-cookie"
import {DELETE_MEDIA} from "./graphql/mutation"
const cookies = new Cookies()

export default function MediaScreen({selectMedia, selectedIds, deleteAble = false}) {
  const [DeleteAction, {loading: deleteLoading}] = useMutation(DELETE_MEDIA)
  const [list, setList] = useState([])
  const [pageInfo, setPageInfo] = useState()
  const [moreLoading, setMoreloading] = useState(false)
  const [deleteMedia, setDeleteMedia] = useState()
  const {data, loading, fetchMore, refetch} = useQuery(GET_MEDIA_LIST, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setList(data.MediaList.edges.map((edge) => edge.node))
      setPageInfo(data.MediaList.pageInfo)
    },
  })
  const onMore = async () => {
    setMoreloading(true)
    if (pageInfo.hasNextPage) {
      const moreresult = await fetchMore({
        variables: {
          skip: pageInfo.endCursor,
        },
      })
      setList(list.concat(moreresult.data.MediaList.edges.map((edge) => edge.node)))
      setPageInfo(moreresult.data.MediaList.pageInfo)
    }
    setMoreloading(false)
  }

  const inputRef = useRef(null)
  const handleUploadClick = () => {
    inputRef.current?.click()
  }
  const handleFileChange = async (e) => {
    if (!e.target.files) {
      return
    }
    const response = await axios.post(`${process.env.REACT_APP_IMAGE_UPLOAD_URL}`, Array.from(e.target.files), {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: cookies.get("token"),
      },
    })
    if (response?.data?.files?.length > 0) {
      refetch()
    }
  }

  const deleteMediaHandler = async () => {
    if (deleteMedia) {
      await DeleteAction({
        variables: {
          id: deleteMedia.id,
        },
      })
      setDeleteMedia("")
      refetch()
    }
  }

  return (
    <div className="media-wrap">
      <div className="d-flex">
        <a href="#_" onClick={handleUploadClick} className="fs-13 mb-4 btn btn-light d-inline-block">
          Upload image
        </a>
        {deleteAble && deleteMedia && (
          <a href="#_" onClick={deleteMediaHandler} className="fs-13 ms-4 btn btn-danger d-inline-block">
            {deleteLoading ? "Уншиж байна ..." : "Delete image"}
          </a>
        )}
      </div>

      <input type="file" ref={inputRef} onChange={handleFileChange} multiple style={{display: "none"}} />

      <div className="media-list-wrap">
        {list.map((m, k) => {
          return (
            <div
              className={`media-item ${selectedIds?.filter((id) => id === m.id)?.length > 0 ? "selected" : ""} ${deleteMedia?.id === m.id ? "delete-selected" : ""}`}
              onClick={() => {
                if (typeof selectMedia === "function") selectMedia(m)
                if (deleteAble) setDeleteMedia(m)
              }}
              key={k}
              style={{backgroundImage: `url(${GetAvatarUrl(m.mediaImageGenerateds, "DEFAULT_THUMB")})`}}
            ></div>
          )
        })}
      </div>

      {pageInfo?.hasNextPage && (
        <div className="d-flex align-items-center justify-content-center py-5">
          <button className="btn btn-light" onClick={onMore}>
            {moreLoading ? "Уншиж байна..." : "Илүү харах"} {list.length}/{pageInfo.count}
          </button>
        </div>
      )}
    </div>
  )
}
