import {useMutation} from "@apollo/client"
import {useForm} from "react-hook-form"
import {CREATE_PRODUCT} from "./graphql/mutation"
import Cookies from "universal-cookie"
import axios from "axios"
import {useRef, useState} from "react"
import {GetAvatarUrl} from "../../utils/getAvatarUrl"
import SpinLoader from "../../components/loaders/SpinLoader"
import {toast} from "react-hot-toast"
import {Link, useNavigate} from "react-router-dom"
import ProductForm from "./form/ProductForm"
const cookies = new Cookies()

export default function AddProductScreen() {
  const navigate = useNavigate()
  const [AddProduct, {loading}] = useMutation(CREATE_PRODUCT)
  const [images, setImages] = useState([])
  const [featured, setFeatured] = useState()

  const onSubmit = async (data) => {
    const response = await AddProduct({
      variables: data,
    })
    toast.success("Бүтээгдэхүүн нэмэгдлээ")
    navigate("/products")
  }

  const [file, setFile] = useState()
  const inputRef = useRef(null)

  const handleUploadClick = () => {
    inputRef.current?.click()
  }

  const handleFileChange = async (e) => {
    if (!e.target.files) {
      return
    }
    const response = await axios.post("http://localhost:5000/api/upload/images", Array.from(e.target.files), {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: cookies.get("token"),
      },
    })
    setImages(response.data.files)
  }

  return (
    <div className="">
      <div className="gh-canvas-header">
        <div className="gh-canvas-header-content">
          <div className="flex flex-column">
            <div className="gh-canvas-breadcrumb">
              <Link to="/products">Бүтээгдэхүүн</Link>
              <svg viewBox="0 0 18 27">
                <path
                  d="M2.397 25.426l13.143-11.5-13.143-11.5"
                  strokeWidth="3"
                  stroke="#0B0B0A"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
              Шинэ
            </div>
            <h2 className="gh-canvas-title">
              <a href="/products" className="active ">
                Шинэ бүтээгдэхүүн
              </a>
            </h2>
          </div>
        </div>
      </div>

      <div className="">
        <div className="row g-5">
          <div className="col-md-7 col-lg-8  position-relative">
            {loading && (
              <div className="pos-abs filled h-100 bg-loader">
                <SpinLoader loading={true} />
              </div>
            )}
            <ProductForm onSubmit={onSubmit} />
            {/* <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row g-3">
                  <div className="col-sm-12">
                    <label htmlFor="title" className="form-label">
                      Title
                    </label>
                    <input type="text" className="form-control" id="title" {...register("title", {required: true})} />
                    {errors.title && <div className="">Title is required.</div>}
                  </div>
                  <div className="col-sm-6">
                    <label htmlFor="code" className="form-label">
                      Code
                    </label>
                    <input type="text" className="form-control" id="code" {...register("code", {required: true})} />
                    {errors.title && <div className="">Code is required.</div>}
                  </div>
                  <div className="col-sm-6">
                    <label htmlFor="code" className="form-label">
                      Ply
                    </label>
                    <input type="text" className="form-control" id="code" {...register("code", {required: true})} />
                    {errors.title && <div className="">Code is required.</div>}
                  </div>
                  <div className="col-sm-12">
                    <label htmlFor="description" className="form-label">
                      Description
                    </label>
                    <textarea className="form-control" id="description" {...register("description")} />
                  </div>
                  <div className="col-sm-6">
                    <label htmlFor="code" className="form-label">
                      Color
                    </label>
                    <input type="text" className="form-control" id="code" {...register("code", {required: true})} />
                    {errors.title && <div className="">Code is required.</div>}
                  </div>
                  <div className="col-sm-6">
                    <label htmlFor="code" className="form-label">
                      Material
                    </label>
                    <input type="text" className="form-control" id="code" {...register("code", {required: true})} />
                    {errors.title && <div className="">Code is required.</div>}
                  </div>

                  <div className="col-sm-12">
                    <div>
                      <label htmlFor="description" className="form-label">
                        Нүүр зураг
                      </label>
                      {featured && (
                        <div className="photo-thumb">
                          <img src={GetAvatarUrl(featured.mediaImageGenerateds, "PRODUCT_MEDIUM")} alt="" />
                        </div>
                      )}
                    </div>

                    <label htmlFor="description" className="form-label">
                      Зурагууд
                    </label>
                    <div className="d-flex">
                      {images.map((image, key) => {
                        return (
                          <div key={key} className="photo-thumb" onClick={() => setFeatured(image)}>
                            <img src={GetAvatarUrl(image.mediaImageGenerateds, "PRODUCT_MEDIUM")} alt="" />
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <button onClick={handleUploadClick}>{file ? `${file.name}` : "Click to select"}</button>

                    <input type="file" ref={inputRef} onChange={handleFileChange} multiple style={{display: "none"}} />
                  </div>
                </div>

                <hr className="my-4" />
                <button className="w-100 btn btn-primary btn-lg" type="submit">
                  Add product
                </button>
              </form> */}
          </div>
        </div>
      </div>
    </div>
  )
}
