import {useForm} from "react-hook-form"

export default function AdminForm({submitForm, user}) {
  const {
    register,
    handleSubmit,
    watch,
    formState: {errors},
  } = useForm({
    defaultValues: {
      email: user?.email,
    },
  })
 

  return (
    <div className="gh-main-section-content grey">
      <form onSubmit={handleSubmit(submitForm)}>
        <div className="row">
          <div className="col-md-8">
            <div className="mb-3">
              <label className="form-label">
                Бүртгэлтэй э-мэйл хаяг  <span className="text-danger">*</span>
              </label>
              <input type="email" className="form-control" {...register("email", {required: true})} />
            </div>
          </div>
        </div>
        
        
        <div className="mt-3">
          <button type="submit" className="btn btn-dark btn-lg">
            Хадгалах
          </button>
        </div>
      </form>
    </div>
  )
}
