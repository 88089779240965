import {gql} from "@apollo/client"
export const GET_LOG_LIST = gql`
  query LogList($skip: Int, $search: String, $action: String, $userId: Int) {
    LogList(skip: $skip, search: $search, action: $action, userId: $userId) {
      edges {
        node {
          id
          userType
          userEmail
          userAction
          actionDetail
          ipAddress
          createdAt
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
        count
      }
    }
  }
`
