import React from "react"

export default function SpinLoader({ loading = false, absolute = false, small = false, light = false }) {
  if (!loading) return ""

  const width = small ? "1.2rem" : "3rem",
    height = small ? "1.2rem" : "3rem"
  return (
    <div
      className={`spinner-border-wrap d-flex w-100 h-100 p-5 justify-content-center align-items-center ${
        absolute ? "pos-abs filled loader-bg" : "pos-rel"
      }`}
    >
      <div className={`spinner-border ${light ? "light" : ""}  ${small ? "small" : ""}`} style={{ width: width, height: height }} role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  )
}
