import {useForm} from "react-hook-form"

import Editor from "./Editor"

export default function TermForm({submitForm, term}) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm({
    defaultValues: {
      title: term?.title,
      content: term?.content,
    },
  })

  const setContent = (value) => {
    setValue("content", value)
  }

  return (
    <div className="gh-main-section-content grey">
      <form onSubmit={handleSubmit(submitForm)}>
        <div className="row">
          <div className="col-md-8">
            <div className="mb-3">
              <label className="form-label">Гарчиг</label>
              <input type="text" className="form-control" {...register("title", {required: true})} />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-8">
            <div className="mb-3">
              <label className="form-label">Нөхцөл дэлгэрэнгүй</label>
              <Editor onChangeContent={setContent} content={term?.content} />
            </div>
          </div>
        </div>

        <div className="mt-3 d-flex justify-content-between">
          <button type="submit" className="btn btn-dark btn-lg">
            Хадгалах
          </button>
        </div>
      </form>
    </div>
  )
}
