import {useMutation} from "@apollo/client"
import React from "react"
import {Link, Navigate} from "react-router-dom"
import SpinLoader from "../../components/loaders/SpinLoader"
import UserForm from "./form/UserForm"
import {ADD_CLIENT} from "./graphql/mutation"

export default function AddConsumerScreen() {
  const [AddClient, {loading, data}] = useMutation(ADD_CLIENT)
  const submitForm = async (values) => {
    await AddClient({
      variables: {
        email: values.email,
        password: values.password,
        firstname: values.firstname,
        lastname: values.lastname,
        businessName: values.businessName,
        phone: values.phone,
        clientDescription: values.clientDescription,
        businessDescription: values.businessDescription,
        numberOfStores: values.numberOfStores?.toString(),
        specialInstructions: values.specialInstructions,
        shippingAddress: values.shippingAddress,
        billingAddress: values.billingAddress,
        website: values.website,
      },
    })
  }

  if (data?.addClient) return <Navigate to={"/consumers"} replace />

  return (
    <div className="page-wrapper">
      <div className="gh-canvas-header">
        <div className="gh-canvas-header-content">
          <div className="flex flex-column">
            <div className="gh-canvas-breadcrumb">
              <Link to="/consumers">Харилцагчид</Link>
              <svg viewBox="0 0 18 27">
                <path
                  d="M2.397 25.426l13.143-11.5-13.143-11.5"
                  strokeWidth="3"
                  stroke="#0B0B0A"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
              Шинэ
            </div>
            <h2 className="gh-canvas-title">
              <a href="/consumers/add" className="active ">
                Хэрэглэгч нэмэх
              </a>
            </h2>
          </div>
        </div>
      </div>
      <div className="position-relative">
        <SpinLoader loading={loading} absolute />
        <UserForm submitForm={submitForm} />
      </div>
    </div>
  )
}
