import {useForm} from "react-hook-form"

import {useInit} from "../../../providers/Init"
import {useRef, useState} from "react"
import axios from "axios"
import Cookies from "universal-cookie"
import {GetAvatarUrl} from "../../../utils/getAvatarUrl"
import Select from "react-select"
import MediaChooserFromLibrary from "../../media/MediaChooserFromLibrary"
import Editor from "./Editor"
const cookies = new Cookies()

export default function PostForm({submitForm, post}) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm({
    defaultValues: {
      status: post?.status,
      title: post?.title,
      intro: post?.intro,
      excerpt: post?.excerpt,
      content: post?.content,
      thumbnailId: post?.thumbnailId,
    },
  })

  // const navigate = useNavigate()
  // const {modalId, showModal} = useModal()

  const [file, setFile] = useState(post?.thumbnail)
  const inputRef = useRef(null)

  const handleUploadClick = () => {
    inputRef.current?.click()
  }

  const handleFileChange = async (e) => {
    if (!e.target.files) {
      return
    }
    const response = await axios.post(`${process.env.REACT_APP_IMAGE_UPLOAD_URL}?purpose=post`, Array.from(e.target.files), {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: cookies.get("token"),
      },
    })
    if (response?.data?.files?.length > 0) {
      setFile(response.data.files[0])
      // console.log("response.data.files[0].id:", response.data.files[0].id)
      setValue("thumbnailId", response.data.files[0].id)
    }
  }

  const removePhoto = () => {
    setFile("")
    setValue("thumbnailId", "")
  }

  const handleChangeTag = async (newValue, action) => {
    setValue("parentsId", newValue)
  }

  const setMediaFromLibrary = (m) => {
    // console.log("setMediaFromLibrary:", m[0])
    setFile(m[0])
    setValue("thumbnailId", m[0].id)
  }

  const setContent = (value) => {
    setValue("content", value)
  }

  return (
    <div className="gh-main-section-content grey">
      <form onSubmit={handleSubmit(submitForm)}>
        <div className="row">
          <div className="col-md-8">
            <div className="mb-3">
              <label className="form-label">Гарчиг</label>
              <input type="text" className="form-control" {...register("title", {required: true})} />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-8">
            <div className="mb-3">
              <label className="form-label">Агуулга</label>
              <Editor onChangeContent={setContent} content={post?.content} />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-8">
            <div className="mb-3">
              <label className="form-label">Богино тайлбар</label>
              <input type="text" className="form-control" {...register("excerpt", {required: false})} />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-8">
            <div className="mb-3">
              <label className="form-label">Нүүр зураг</label>
              <div className="d-flex justify-content-between align-items-center pb-3">
                <div className="">
                  <MediaChooserFromLibrary setMedia={setMediaFromLibrary} />
                  <a href="#_" onClick={handleUploadClick} className="fs-13">
                    Upload image
                  </a>
                  {file && (
                    <a href="#_" onClick={removePhoto} className="fs-13 ms-4">
                      Remove image
                    </a>
                  )}

                  <input type="file" ref={inputRef} onChange={handleFileChange} multiple style={{display: "none"}} />
                </div>
              </div>
              {file && (
                <div className="thumb-prev">
                  <img src={GetAvatarUrl(file.mediaImageGenerateds, "POST_THUMBNAIL")} alt="" />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="mb-3">
              <label className="form-label">
                Төлөв <span className="text-danger">*</span>
                <input type="hidden" className="form-control" {...register("thumbnailId", {required: false})} />
              </label>
              <select className="form-control" {...register("status", {required: true})}>
                <option value={"PUBLIC"}>Нийтлэгдсэн</option>
                <option value={"DRAFT"}>Ноорог</option>
              </select>
            </div>
          </div>
        </div>
        <div className="mt-3 d-flex justify-content-between">
          <button type="submit" className="btn btn-dark btn-lg">
            Хадгалах
          </button>
        </div>
      </form>

      {/* <Modal id={`userDeleteModal${user.id}`}>
        <Modal.Body>
          <SpinLoader loading={deleteLoading} absolute />
          <h4>Устгах уу?</h4>

          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center justify-content-start">
              <button
                className="btn btn-danger"
                onClick={() => {
                  DeleteClient({
                    variables: {
                      id: user.id,
                    },
                    onCompleted: (d) => {
                      showModal("")
                      if (d.deleteClient) {
                        toast.success("Устгагдлаа")
                        navigate("/consumers")
                      } else {
                        toast.error("Алдаа гарлаа")
                      }
                    },
                  })
                }}
              >
                Устгах
              </button>
              <button className="btn btn-light ms-2" onClick={() => showModal("")}>
                Болих
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}
    </div>
  )
}
