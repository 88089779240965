import {useForm} from "react-hook-form"
import {useModal} from "../../providers/Modal"
import {useMutation} from "@apollo/client"
import SpinLoader from "../../components/loaders/SpinLoader"
import Modal from "../../components/Modal"
import {UPDATE_PRICE_GROUP} from "./graphql/mutation"
import {useInit} from "../../providers/Init"

export default function GroupItem({item}) {
  const {settings} = useInit()
  const {modalId, showModal} = useModal()
  const {register, handleSubmit} = useForm({
    defaultValues: item,
  })
  const [UpdateGroup, {loading, data}] = useMutation(UPDATE_PRICE_GROUP)



  const submitGroupForm = async (values) => {
    const response = await UpdateGroup({
      variables: {
        id: item.id,
        name: values.name,
        currencyId: values.currencyId,
        exchange_price: parseInt(values.exchange_price),
      },
      refetchQueries: ["GetSettings"],
    })
    showModal("")
  }

  return (
    <>
      <tr>
        <td scope="col" className="px-4 w-100">
          <div className="d-flex align-items-center">
            <div className="">
              <span className="fw-bold mb-0 fs-14">{item.name}</span>
              <span className="text-muted mb-0 fs-13 ms-4">
                Валют: {item.currencyId} ({item.exchange_price})
              </span>
            </div>
          </div>
        </td>

        <td scope="col" className="">
          <a href="#_" className="btn btn-light btn-sm btn-rounded" onClick={() => showModal(`editGroupModal${item.id}`)}>
            Засах
          </a>
        </td>
      </tr>

      <Modal darkBackdrop id={`editGroupModal${item.id}`}>
        <Modal.Body>
          <SpinLoader loading={loading} absolute />
          <form onSubmit={handleSubmit(submitGroupForm)}>
            <div className="mb-3">
              <label className="form-label">Нэр</label>
              <input type="text" className="form-control" {...register("name", {required: true})} />
            </div>

            <div className="mb-3">
              <label className="form-label">Валют</label>
              <select className="form-control" {...register("currencyId", {required: true})}>
                {settings?.currency?.map((c, key) => {
                  return (
                    <option value={c.id} key={key}>
                      {c.id}
                    </option>
                  )
                })}
              </select>
            </div>
            <div className="mb-3">
              <label className="form-label">Ханш</label>
              <input type="text" className="form-control" {...register("exchange_price", {required: true})} />
            </div>
            {/* 
            <div className="mb-3">
              <label className="form-label">Хямдрал (%)</label>
              <input type="number" className="form-control" {...register("sale", {required: false})} />
            </div> */}

            <div className="mt-3 d-flex justify-content-between">
              <button type="submit" className="btn btn-dark btn-lg">
                Хадгалах
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}
