import { Link } from "react-router-dom";
import ProductsScreen from "../modules/product/ProductsScreen";

export default function Products() {
  return (
    <>
      <ProductsScreen />
    </>
  )
}
