/* eslint-disable react-hooks/exhaustive-deps */
import {default as React, useEffect, useRef} from "react"
import EditorJS from "@editorjs/editorjs"
import Header from "@editorjs/header"
import Embed from "@editorjs/embed"
import Delimiter from "@editorjs/delimiter"
import ImageTool from "@editorjs/image"
import Quote from "@editorjs/quote"

import Cookies from "universal-cookie"
import axios from "axios"
import {GetAvatarUrl} from "../../../utils/getAvatarUrl"
const cookies = new Cookies()

const EDITTOR_HOLDER_ID = "editorjs"

const Editor = ({onChangeContent, content}) => {
  const editorInstance = useRef()
  const [editorData, setEditorData] = React.useState(content || "")

  // This will run only once
  useEffect(() => {
    if (!editorInstance.current) {
      initEditor()
    }
    return () => {
      editorInstance.current.destroy()
      editorInstance.current = null
    }
  }, [])

  const initEditor = () => {
    const editor = new EditorJS({
      holder: EDITTOR_HOLDER_ID,

      tools: {
        heading: {
          class: Header,
          config: {
            placeholder: "Дэд гарчиг бичих",
            levels: [2, 3],
            defaultLevel: 2,
          },
        },
        image: {
          class: ImageTool,
          config: {
            captionPlaceholder: "Зургийн тайлбар бичих",
            additionalRequestHeaders: {
              // Authorization: cookies.get("token"),
            },
            uploader: {
              async uploadByFile(file) {
                var formData = new FormData()
                const token = cookies.get("token")
                formData.append("file", file, file.name)

                let response = await axios({
                  method: "post",
                  url: `${process.env.REACT_APP_IMAGE_UPLOAD_URL}?purpose=post`,
                  headers: {
                    Authorization: token,
                  },
                  data: formData,
                })
                if (response?.data?.files?.length > 0) {
                  return {
                    success: 1,
                    file: {
                      url: GetAvatarUrl(response.data.files[0].mediaImageGenerateds, "POST_IMAGE"),
                    },
                  }
                }
                return {
                  success: 0,
                }
              },
              // async uploadByUrl(url) {
              //   var visual_source = url
              //   var visual_ext = visual_source.split(".").pop()
              //   var visual = await fetch(visual_source)
              //   // console.log(visual)
              //   var visual_as_blob = await visual.blob()
              //   // console.log(visual_as_blob)
              //   var visual_as_file = new File([visual_as_blob], "filebyurl-" + Date.now() + "." + visual_ext, {
              //     lastModified: new Date().getTime(),
              //     type: visual_as_blob.type,
              //   })
              //   // console.log(visual_as_file)
              //   const formData = new FormData()
              //   formData.append("file", visual_as_file, url)
              //   const token = cookies.get("accessToken")
              //   // formData.append("file", file)
              //   let query = `?subAction=post_image`
              //   let res = await axios({
              //     method: "post",
              //     url: `${process.env.REACT_APP_RESTAPI_URL}/upload/media${query}`,
              //     headers: {
              //       Authorization: token,
              //     },
              //     data: formData,
              //   })
              //   if (res.data?.resized?.data?.length > 0) {
              //     // addImages(res.data.file)
              //     return {
              //       success: 1,
              //       file: {
              //         url: res.data?.resized?.data[0].src,
              //       },
              //     }
              //   }
              //   return {
              //     success: 0,
              //   }
              // },
            },
            buttonContent: "Зураг хуулах",
          },
        },
        embed: Embed,
        delimiter: Delimiter,
        quote: Quote,
      },
      logLevel: "ERROR",
      data: editorData ? JSON.parse(editorData) : {},
      placeholder: "Энд бичнэ үү!",
      onReady: () => {
        editorInstance.current = editor
      },
      onChange: async (api, event) => {
        editor
          .save()
          .then((outputData) => {
            // console.log("outputData:", outputData)
            onChangeContent(JSON.stringify(outputData))
          })
          .catch((error) => {
            console.log("editor error: ", error)
          })
      },
      autoFocus: false,
    })
  }

  return (
    <React.Fragment>
      <div className="editor-wrap" id={EDITTOR_HOLDER_ID}></div>
    </React.Fragment>
  )
}

export default Editor
