import React, {useEffect} from "react"
import {useQuery} from "@apollo/client"
import {GET_PRODUCT_LIST} from "./graphql/query"
import {Link} from "react-router-dom"
import {GetAvatarUrl} from "../../utils/getAvatarUrl"
import useInfiniteScroll from "../../utils/InfiniteScroll"
import ProductListItem from "./components/ProductListItem"

export default function ProductsScreen() {
  const [customLoad, setCustomLoad] = React.useState(false)
  const [list, setList] = React.useState([])
  const [search, setSearch] = React.useState()
  const [status, setStatus] = React.useState("")
  const [pageInfo, setPageInfo] = React.useState()
  const {loading, fetchMore, refetch} = useQuery(GET_PRODUCT_LIST, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {},
    onCompleted: (data) => {
      setList(data.GetProductListResponse.edges.map((edge) => edge.node))

      setPageInfo(data.GetProductListResponse.pageInfo)
    },
  })
  const onMore = async () => {
    if (pageInfo.hasNextPage) {
      const moreresult = await fetchMore({
        variables: {
          search: search,
          skip: pageInfo.endCursor,
          status: status,
        },
      })
      setList(list.concat(moreresult.data.GetProductListResponse.edges.map((edge) => edge.node)))
      setPageInfo(moreresult.data.GetProductListResponse.pageInfo)
    }
  }

  const onFilter = async () => {
    setList([])
    setCustomLoad(true)
    console.log("onFilter ==============\n")

    const result = await fetchMore({
      variables: {
        search: search,
        status: status,
        skip: 0,
      },
    })

    setList(result.data.GetProductListResponse.edges.map((edge) => edge.node))
    setPageInfo(result.data.GetProductListResponse.pageInfo)
    setCustomLoad(false)
  }

  const [lastElementRef] = useInfiniteScroll(pageInfo?.hasNextPage ? () => onMore() : () => {}, loading)

  console.log("loading ==============\n", loading)
  return (
    <div className="">
      <div className="gh-canvas-header">
        <div className="gh-canvas-header-content">
          <div className="flex flex-column">
            <h2 className="gh-canvas-title">
              <a href="/products" className="active ">
                Бүтээгдэхүүн
              </a>
            </h2>
          </div>
          <div className="view-actions">
            <Link to="/products/add" type="button" className="btn  btn-dark">
              Шинэ бүтээгдэхүүн
            </Link>
          </div>
        </div>
      </div>
      <div className="">
        <div className="filter-box">
          <div className="row row-cols-lg-auto g-2 align-items-center ">
            <div className="col-12">
              <input type="search" onChange={(e) => setSearch(e.target.value)} className="form-control form-control-sm" placeholder="Хайх" />
            </div>

            <div className="col-12">
              <select className="form-control" onChange={(e) => setStatus(e.target.value)}>
                <option value="">All status</option>
                <option value="ACTIVE">ACTIVE</option>
                <option value="INACTIVE">INACTIVE</option>
              </select>
            </div>
            <div className="col-12">
              <button className="btn btn-dark" onClick={onFilter}>
                Хайх
              </button>
            </div>
          </div>
        </div>
        {customLoad ? (
          <>Loading ...</>
        ) : (
          <>
            {list.map((product, key) => {
              console.log(product)
              return <ProductListItem item={product} key={key} />
            })}
          </>
        )}

        {/* <div className="table-responsive">
          <table className="table align-middle mb-0 bg-white">
            <thead className="bg-light">
              <tr>
                <th scope="col" className="">
                  Нэр
                </th>
                <th scope="col" className=""></th>
              </tr>
            </thead>
            <tbody>
              {list.map((product, key) => {
                return (
                  <tr key={key}>
                    <td className="px-4  w-100">
                      <div className="d-flex align-items-center">
                        <img src={GetAvatarUrl(product.thumbnail?.mediaImageGenerateds, "PRODUCT_MEDIUM")} className="product-list-thumb" alt="" />
                        <div className="ms-3">
                          <p className="fw-bold mb-0 fs-14">{product.name}</p>
                          <p className="text-muted mb-0 fs-13">
                            code: {product.code}, ply: {product.ply}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="px-4">
                      <Link to={`/products/edit/${product.id}`} className="btn btn-light btn-sm btn-rounded">
                        Засах
                      </Link>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div> */}
        {pageInfo?.hasNextPage && <div className="lastDiv" ref={lastElementRef}></div>}
      </div>
    </div>
  )
}
