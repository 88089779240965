import {gql} from "@apollo/client"

export const UPDATE_REQUEST_STATUS = gql`
  mutation UpdateRequestStatus($status: String!, $id: Int!) {
    UpdateRequestStatus(status: $status, id: $id)
  }
`
export const DELETE_REQUEST = gql`
  mutation DeleteRequestByAdmin($id: Int!) {
    DeleteRequestByAdmin(id: $id)
  }
`
