import React from "react"
import ReactDOM from "react-dom/client"
import "bootstrap/dist/css/bootstrap.min.css"
import "./global.css"
import {RouterProvider} from "react-router-dom"
import reportWebVitals from "./reportWebVitals"
import routes from "./routes"

const Providers = React.lazy(() => import(/* webpackChunkName: "providers" */ "./providers"))

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.Suspense fallback={<>...</>}>
    <Providers>
      <RouterProvider router={routes} />
    </Providers>
  </React.Suspense>
)
reportWebVitals()
