import {useForm} from "react-hook-form"

import {useInit} from "../../../providers/Init"
import {useRef, useState} from "react"
import axios from "axios"
import Cookies from "universal-cookie"
import {GetAvatarUrl} from "../../../utils/getAvatarUrl"
import Select from "react-select"
import MediaChooserFromLibrary from "../../media/MediaChooserFromLibrary"
const cookies = new Cookies()

export default function TagForm({submitForm, tag}) {
  const {groups} = useInit()

  let allTags = []
  groups.forEach((g) => {
    g?.tags?.forEach((t) => {
      allTags.push(t)
    })
  })
  let defaultTags = []
  if (allTags?.length > 0 && tag?.tagParents?.length > 0) {
    for (let i = 0; i < allTags.length; i++) {
      let id = allTags[i].id
      let d = tag.tagParents?.filter((t) => t.parent.id === id).map((t) => ({value: t.parent.id, label: t.parent.name}))

      if (d.length > 0) {
        defaultTags = defaultTags.concat(d)
      }
    }
  }

  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm({
    defaultValues: {
      name: tag?.name,
      code: tag?.code,
      description: tag?.description,
      order: tag?.order,
      groupId: tag?.groupId,
      thumbnailId: tag?.thumbnailId,
      parentsId: defaultTags,
    },
  })

  // const navigate = useNavigate()
  // const {modalId, showModal} = useModal()

  const [file, setFile] = useState(tag?.thumbnail)
  const inputRef = useRef(null)

  const handleUploadClick = () => {
    inputRef.current?.click()
  }

  const handleFileChange = async (e) => {
    if (!e.target.files) {
      return
    }
    const response = await axios.post(`${process.env.REACT_APP_IMAGE_UPLOAD_URL}?purpose=category`, Array.from(e.target.files), {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: cookies.get("token"),
      },
    })
    if (response?.data?.files?.length > 0) {
      setFile(response.data.files[0])
      // console.log("response.data.files[0].id:", response.data.files[0].id)
      setValue("thumbnailId", response.data.files[0].id)
    }
  }

  const removePhoto = () => {
    setFile("")
    setValue("thumbnailId", "")
  }

  const handleChangeTag = async (newValue, action) => {
    setValue("parentsId", newValue)
  }

  const setMediaFromLibrary = (m) => {
    // console.log("setMediaFromLibrary:", m[0])
    setFile(m[0])
    setValue("thumbnailId", m[0].id)
  }

  // console.log("defaultTags:", defaultTags)

  return (
    <div className="gh-main-section-content grey">
      <form onSubmit={handleSubmit(submitForm)}>
        <div className="row">
          <div className="col-md-8">
            <div className="mb-3">
              <label className="form-label">
                Type <span className="text-danger">*</span>
                <input type="hidden" className="form-control" {...register("thumbnailId", {required: false})} />
              </label>
              <select className="form-control" {...register("groupId", {required: true})}>
                {groups.map((g, k) => {
                  return (
                    <option value={g.id} key={k}>
                      {g.name}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="mb-3">
              <label className="form-label">
                Name <span className="text-danger">*</span>
              </label>
              <input type="text" className="form-control" {...register("name", {required: true})} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="mb-3">
              <label className="form-label">Code</label>
              <input type="text" className="form-control" {...register("code", {required: false})} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="mb-3">
              <label className="form-label">Description</label>
              <input type="text" className="form-control" {...register("description", {required: false})} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="mb-3">
              <label className="form-label">Order</label>
              <input type="number" className="form-control" {...register("order", {required: false})} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="form-group mb-3">
              <label className="form-label">Parents</label>
              <Select
                // value={defaultTags}
                defaultValue={defaultTags}
                isMulti
                name={"parents"}
                options={allTags?.map((t) => ({value: t.id, label: t.name}))}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={handleChangeTag}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="mb-3">
              <label className="form-label">Зураг</label>
              <div className="d-flex justify-content-between align-items-center pb-3">
                <div className="">
                  <MediaChooserFromLibrary setMedia={setMediaFromLibrary}  />
                  <a href="#_" onClick={handleUploadClick} className="fs-13">
                    Upload image
                  </a>
                  {file && (
                    <a href="#_" onClick={removePhoto} className="fs-13 ms-4">
                      Remove image
                    </a>
                  )}

                  <input type="file" ref={inputRef} onChange={handleFileChange} multiple style={{display: "none"}} />
                </div>
              </div>
              {file && (
                <div className="thumb-prev">
                  <img src={GetAvatarUrl(file.mediaImageGenerateds, "DEFAULT_THUMB")} alt="" />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="mt-3 d-flex justify-content-between">
          <button type="submit" className="btn btn-dark btn-lg">
            Хадгалах
          </button>
        </div>
      </form>

      {/* <Modal id={`userDeleteModal${user.id}`}>
        <Modal.Body>
          <SpinLoader loading={deleteLoading} absolute />
          <h4>Устгах уу?</h4>

          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center justify-content-start">
              <button
                className="btn btn-danger"
                onClick={() => {
                  DeleteClient({
                    variables: {
                      id: user.id,
                    },
                    onCompleted: (d) => {
                      showModal("")
                      if (d.deleteClient) {
                        toast.success("Устгагдлаа")
                        navigate("/consumers")
                      } else {
                        toast.error("Алдаа гарлаа")
                      }
                    },
                  })
                }}
              >
                Устгах
              </button>
              <button className="btn btn-light ms-2" onClick={() => showModal("")}>
                Болих
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}
    </div>
  )
}
