import React from "react"
import {useQuery} from "@apollo/client"

import {Link} from "react-router-dom"
import {GetAvatarUrl} from "../../utils/getAvatarUrl"
import {GET_ALL_ORDER_LIST} from "./graphql/query"
import useInfiniteScroll from "../../utils/InfiniteScroll"
import EmptyBox from "../../components/EmptyBox"
import DownloadOrderExcel from "./DownloadOrderExcel"
import SpinLoader from "../../components/loaders/SpinLoader"
import OrderRowItem from "./OrderRowItem"

export default function OrderScreen() {
  const [search, setSearch] = React.useState()
  const [status, setStatus] = React.useState()
  const [isMore, setIsMore] = React.useState(false)
  const [list, setList] = React.useState([])
  const [pageInfo, setPageInfo] = React.useState()
  const {loading, fetchMore, refetch} = useQuery(GET_ALL_ORDER_LIST, {
    fetchPolicy: "network-only",
    variables: {},
    onCompleted: (data) => {
      if (data?.GetAllOrderList?.edges?.length === 0) {
        setIsMore(true)
      }
      setList(data.GetAllOrderList.edges.map((edge) => edge.node))
      setPageInfo(data.GetAllOrderList.pageInfo)
    },
  })
  const onMore = async () => {
    if (pageInfo.hasNextPage) {
      const moreresult = await fetchMore({
        variables: {
          search: search,
          skip: pageInfo.endCursor,
        },
      })
      setIsMore(true)
      setList(list.concat(moreresult.data.GetAllOrderList.edges.map((edge) => edge.node)))
      setPageInfo(moreresult.data.GetAllOrderList.pageInfo)
    }
  }
  // console.log("list:", list)

  const [lastElementRef] = useInfiniteScroll(pageInfo?.hasNextPage ? () => onMore() : () => {}, loading)

  return (
    <div className="page-wrapper">
      <div className="gh-canvas-header">
        <div className="gh-canvas-header-content">
          <div className="flex flex-column">
            <h2 className="gh-canvas-title">Захиалга</h2>
          </div>
          <div className="view-actions">
            {/* <Link to="/consumers/add" type="button" className="btn  btn-dark">
              Хэрэглэгч нэмэх
            </Link> */}
          </div>
        </div>
      </div>

      <div className="">
        <div className="filter-box">
          <div className="row row-cols-lg-auto g-2 align-items-center ">
            <div className="col-12">
              <select
                className="form-select"
                onChange={(e) => {
                  setSearch(e.target.value)
                  refetch({
                    status: e.target.value,
                  })
                }}
              >
                <option value="">Бүх төлөв</option>
                <option value="pending">Хүлээгдэж байгаа</option>
                <option value="active">Идэвхитэй</option>
                <option value="completed">Амжилттай дууссан</option>
              </select>
            </div>

            <div className="col-12">
              <input
                type="search"
                onChange={(e) => {
                  setSearch(e.target.value)
                  refetch({
                    search: e.target.value,
                  })
                }}
                className="form-control form-control-sm"
                placeholder="Харилцагчаар хайх"
              />
            </div>
          </div>
        </div>

        <div className="table-responsive">
          <table className="table no-wrap user-table mb-0">
            <thead>
              <tr>
                <th scope="col" className="border-0 text-uppercase font-medium ps-2 pe-2">
                  Төлөв
                </th>
                <th scope="col" className="border-0 text-uppercase font-medium">
                  Харилцагч
                </th>
                <th scope="col" className="border-0 text-uppercase font-medium">
                  Бүтээгдэхүүн тоо
                </th>
                <th scope="col" className="border-0 text-uppercase font-medium">
                  Огноо
                </th>
                <th scope="col" className="border-0 text-uppercase font-medium text-end">
                  Засах
                </th>
              </tr>
            </thead>
            <tbody>
              {list.map((order, key) => {
                return <OrderRowItem order={order} refetch={refetch} key={key} />
              })}
            </tbody>
          </table>
        </div>
        {loading && <SpinLoader loading={loading} />}

        {/* <div className="table-responsive">
          <table className="table align-middle mb-0 bg-white ">
            <thead className="bg-light">
              <tr>
                <th scope="col" className="">
                  Төлөв
                </th>
                <th scope="col" className="">
                  Харилцагч
                </th>

                <th scope="col" className="">
                  Бүтээгдэхүүн тоо
                </th>
                <th scope="col" className="">
                  Огноо
                </th>
                <th scope="col" className=""></th>
              </tr>
            </thead>
            <tbody>
              {list.map((order, key) => {
                return <OrderRowItem order={order} refetch={refetch} key={key} />
              })}
            </tbody>
          </table>
          {loading && <SpinLoader loading={loading} />}
        </div> */}
        {!pageInfo?.hasNextPage && !loading && isMore && <EmptyBox />}
        {pageInfo?.hasNextPage && <div className="lastDiv" ref={lastElementRef}></div>}
      </div>
    </div>
  )
}
