const {IsValidUrl} = require("./validators")

exports.isRetinaDisplay = () => {
  if (typeof window !== "undefined") {
    if (window.matchMedia) {
      var mq = window.matchMedia(
        "only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen  and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)"
      )
      return (mq && mq.matches) || window.devicePixelRatio > 1
    }
  } else {
    return false
  }
}

exports.GetAvatarUrl = (avatarArr, getSize, noreturn = false, defaultAvatar = "avatar") => {
  if (avatarArr && Array.isArray(avatarArr) && avatarArr.length > 0) {
    let url
    if (this.isRetinaDisplay()) {
      url = avatarArr.filter((avatar) => avatar.sizeName.toLowerCase() === getSize.toLowerCase() && this.isRetinaDisplay() === avatar.forRetina)[0]
        ?.path
      if (!url) url = avatarArr.filter((avatar) => avatar.sizeName.toLowerCase() === getSize.toLowerCase())[0]?.path
    } else {
      url = avatarArr.filter((avatar) => avatar.sizeName.toLowerCase() === getSize.toLowerCase())[0]?.path
    }

    if (!url) {
      // no size detected
      if (this.isRetinaDisplay()) {
        url = avatarArr.filter((avatar) => this.isRetinaDisplay() === avatar.forRetina)[0]?.path
        if (!url) url = avatarArr[0]?.path
      } else {
        url = avatarArr[0]?.path
      }
    }

    if (IsValidUrl(url)) return url
    if (url) return process.env.REACT_APP_API_IMAGE_URL + url
  }
  return "/assets/thumb.jpg"
}
