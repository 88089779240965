import {gql} from "@apollo/client"

export const ADD_POST = gql`
  mutation AddPost($status: String, $title: String, $intro: String, $excerpt: String, $content: String, $thumbnailId: Int) {
    AddPost(status: $status, title: $title, intro: $intro, excerpt: $excerpt, content: $content, thumbnailId: $thumbnailId)
  }
`
export const UPDATE_POST = gql`
  mutation UpdatePost($id: Int!, $status: String, $title: String, $intro: String, $excerpt: String, $content: String, $thumbnailId: Int) {
    UpdatePost(id: $id, status: $status, title: $title, intro: $intro, excerpt: $excerpt, content: $content, thumbnailId: $thumbnailId)
  }
`
export const DELETE_POST = gql`
  mutation DeletePost($id: Int!) {
    DeletePost(id: $id)
  }
`
