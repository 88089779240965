import {useState, useEffect} from "react"
import {Link} from "react-router-dom"
import {useInit} from "../../../providers/Init"
import {useForm} from "react-hook-form"
import {UPDATE_USER_PRICE} from "../graphql/mutation"
import {useMutation} from "@apollo/client"
import SpinLoader from "../../../components/loaders/SpinLoader"
import UserLog from "./UserLog"
import {useModal} from "../../../providers/Modal"

export default function UserRowForm({user, count}) {
  const {settings} = useInit()
  const {modalId, showModal} = useModal()

  const [showLog, setShowLog] = useState(false)
  const [editPrice, setEditPrice] = useState(false)
  const [priceOption, setPriceOption] = useState(user.user_price_option)

  const {register, handleSubmit} = useForm({
    defaultValues: {
      currencyId: user.user_price_option?.currencyId,
      priceGroupId: user.user_price_option?.priceGroupId,
      show_price: user.user_price_option?.show_price ? "show" : "hide",
    },
  })
  const [UpdateUserPrice, {loading, data}] = useMutation(UPDATE_USER_PRICE)

  const onSubmitPrice = async (data) => {
    const response = await UpdateUserPrice({
      variables: {
        priceGroupId: parseInt(data.priceGroupId),
        show_price: data.show_price == "show" ? true : false,
        userId: user.id,
      },
    })

    if (response?.data?.UpdateUserPrice) {
      setPriceOption(response.data.UpdateUserPrice)
    }
    setEditPrice(false)
  }
  const onCloseEdit = () => {
    setPriceOption(user.user_price_option)
    setEditPrice(false)
  }

  const onShowUserLog = () => {
    setShowLog(true)
    showModal(`userLogModal${user.id}`)
  }
  useEffect(() => {
    if (!modalId && showLog) {
      setShowLog(false)
    }
    return () => {}
  }, [modalId])

  const createdDate = new Date(parseInt(user.createdAt))
  var date = createdDate.getFullYear() + "/" + (createdDate.getMonth() + 1) + "/" + createdDate.getDate()
  var time = createdDate.getHours() + ":" + createdDate.getMinutes() + " минут"


  return (
    <>
      <tr>
        <td className="ps-2 pe-2">{count}</td>
        <td>
          <h6 className="font-medium mb-0">
            {user.profile.firstname} .{user.profile.lastname?.slice(0, 1).toUpperCase()}
          </h6>
          <span className="text-muted">Дэлгүүр: {user.profile.numberOfStores || 0}</span>
        </td>
        <td>
          <span className="text-muted">{user.profile.businessName}</span>
          <br />
          <span className="text-muted">{user.profile.businessDescription}</span>
        </td>
        <td>
          <span className="text-muted">{user.email}</span>
          <br />
          <span className="text-muted">{user.profile.phone}</span>
        </td>

        <td>
          {!editPrice && (
            <div className="d-flex">
              {priceOption ? (
                <>
                  <div>
                    <span className="text-muted">
                      {priceOption.price_group?.name} - {priceOption.price_group?.currencyId} - {priceOption.price_group?.exchange_price}
                    </span>
                    <br />
                    <span className="text-muted">
                      {priceOption.show_price && <span className="badge text-bg-success">Үнэ харна</span>}
                      {!priceOption.show_price && <span className="badge text-bg-secondary">Үнэ харахгүй</span>}
                    </span>
                  </div>
                </>
              ) : (
                <span className="text-muted">Хоосон</span>
              )}

              <div className="edit-icon ms-2 mt-1" onClick={() => setEditPrice(!editPrice)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <g id="vuesax_linear_edit-2" data-name="vuesax/linear/edit-2" transform="translate(-684 -252)">
                    <g id="edit-2">
                      <path id="Vector" d="M9.263,1.439l-8.21,8.69a3.05,3.05,0,0,0-.67,1.43L.013,14.8a1.527,1.527,0,0,0,1.87,1.77l3.22-.55a2.871,2.871,0,0,0,1.39-.75l8.21-8.69c1.42-1.5,2.06-3.21-.15-5.3C12.353-.791,10.683-.061,9.263,1.439Z" transform="translate(687.997 255.7)" fill="none" stroke="#292d32" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                      <path id="Vector-2" data-name="Vector" d="M0,0A6.126,6.126,0,0,0,5.45,5.15" transform="translate(695.89 258.589)" fill="none" stroke="#292d32" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                      <path id="Vector-3" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(684 252)" fill="none" opacity="0" />
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          )}
          {editPrice && (
            <>
              <form onSubmit={handleSubmit(onSubmitPrice)} className="position-relative">
                <SpinLoader loading={loading} absolute />
                <select className="form-control" {...register("priceGroupId")}>
                  {settings.price_group.map((pg, k) => (
                    <option key={k} value={pg.id}>
                      {pg.currencyId} - {pg.name} - {pg.exchange_price}
                    </option>
                  ))}
                </select>
                <select className="form-control" {...register("show_price")}>
                  <option value="show">Үнэ харуулна</option>
                  <option value="hide">Үнэ харуулахгүй</option>
                </select>
                <hr />
                <div className="d-flex">
                  <button type="submit" className="btn btn-sm btn-dark">
                    Хадгалах
                  </button>
                  <button className="btn btn-sm btn-light ms-2" onClick={onCloseEdit}>
                    Болих
                  </button>
                </div>
              </form>
            </>
          )}
        </td>
        <td>
          <span className="text-muted">{user.profile.clientDescription}</span>
          <br />
          <span className="text-muted">{user.profile.specialInstructions}</span>
        </td>
        <td>
          <span className="text-muted">{date}</span>
          <br />
          <span className="text-muted">{time}</span>
        </td>

        <td className="">
          <div className="d-flex justify-content-end align-items-center">
            <div onClick={onShowUserLog} className="edit-icon me-4">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <g id="vuesax_linear_filter-search" data-name="vuesax/linear/filter-search" transform="translate(-492 -700)">
                  <g id="filter-search">
                    <g id="Group">
                      <path id="Vector" d="M11.01,17.06a2.246,2.246,0,0,1-.91,1.72l-1.41.91a2.058,2.058,0,0,1-3.13-1.72V12.62a3.834,3.834,0,0,0-.81-2.12L.91,6.46A3.323,3.323,0,0,1,0,4.44V2.12A2.051,2.051,0,0,1,2.02,0H15.36a2.027,2.027,0,0,1,2.02,2.02V4.24a3.773,3.773,0,0,1-1.01,2.32" transform="translate(495.31 702.01)" fill="none" stroke="#292d32" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    </g>
                    <g id="Group-2" data-name="Group">
                      <path id="Vector-2" data-name="Vector" d="M6.4,3.2A3.2,3.2,0,1,1,3.2,0,3.2,3.2,0,0,1,6.4,3.2Z" transform="translate(504.87 710.12)" fill="none" stroke="#292d32" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                      <path id="Vector-3" data-name="Vector" d="M1,1,0,0" transform="translate(510.87 716.12)" fill="none" stroke="#292d32" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    </g>
                    <path id="Vector-4" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(516 724) rotate(180)" fill="none" opacity="0" />
                  </g>
                </g>
              </svg>
            </div>
            <Link to={`/consumers/edit/${user.id}`} className="btn btn-sm btn-light">
              Засах
            </Link>
          </div>
        </td>
      </tr>
      {showLog && <UserLog user={user} />}
    </>
  )
}
