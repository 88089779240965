import {useMutation} from "@apollo/client"
import React from "react"
import {Link, Navigate} from "react-router-dom"
import SpinLoader from "../../components/loaders/SpinLoader"
import {ADD_POST} from "./graphql/mutation"
import {toast} from "react-hot-toast"
import PostForm from "./form/PostForm"

export default function AddPostScreen() {
  const [AddPost, {loading, data}] = useMutation(ADD_POST)
  const submitForm = async (values) => {
    if (!values?.thumbnailId) delete values.thumbnailId

    await AddPost({
      variables: values,
      onCompleted: (data) => {
        console.log("data:", data)
        toast.success("Хадгалдлаа")
      },
    })
  }

  if (data?.AddPost) return <Navigate to={"/posts"} replace />

  return (
    <div className="page-wrapper">
      <div className="gh-canvas-header">
        <div className="gh-canvas-header-content">
          <div className="flex flex-column">
            <div className="gh-canvas-breadcrumb">
              <Link to="/posts">Нийтлэлүүд</Link>
              <svg viewBox="0 0 18 27">
                <path d="M2.397 25.426l13.143-11.5-13.143-11.5" strokeWidth="3" stroke="#0B0B0A" fill="none" strokeLinecap="round" strokeLinejoin="round"></path>
              </svg>
              Шинэ
            </div>
          </div>
        </div>
      </div>
      <div className="position-relative">
        <SpinLoader loading={loading} absolute />
        <PostForm submitForm={submitForm} />
      </div>
    </div>
  )
}
