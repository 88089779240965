import { Link } from "react-router-dom";
import SortScreen from "../modules/sort/SortScreen";

export default function ProductPhotoSort() {
  return (
    <>
      <SortScreen />
    </>
  )
}
