import {Link} from "react-router-dom"

export default function LogRow({log}) {
  return (
    <>
      <tr>
        <td className="ps-2">
          <span className="text-muted">{new Date(parseInt(log.createdAt)).toLocaleString()}</span>
        </td>
        <td>
          <span className="text-muted">{log.userAction}</span>
        </td>
        <td>
          <span className="text-muted">{log.actionDetail}</span>
        </td>
        <td>
          <span className="text-muted">{log.userEmail}</span>
        </td>
        <td>
          <span className="text-muted">
            {log.userType === "ADMIN" && <span className="badge text-bg-light ">Админ</span>}
            {log.userType === "CLIENT" && <span className="badge text-bg-secondary ">Харилцагч</span>}
          </span>
        </td>
      
        <td>
          <span className="text-muted">{log.ipAddress}</span>
        </td>
      </tr>
    </>
  )
}
