import {useMutation, useQuery} from "@apollo/client"
import React, {useState} from "react"
import {Link, Navigate, useParams} from "react-router-dom"
import SpinLoader from "../../components/loaders/SpinLoader"
import UserForm from "./form/UserForm"
import {GET_ADMIN} from "./graphql/query"
import {UPDATE_CLIENT} from "./graphql/mutation"

export default function EditAdminsScreen() {
  const [user, setUser] = useState()
  const params = useParams()
  const {data: userData, loading: userLoading} = useQuery(GET_ADMIN, {
    variables: {
      id: parseInt(params.id),
    },
    onCompleted: (data) => {
      if (data?.GetAdmin) {
        setUser(data.GetAdmin)
      }
    },
  })

  const [UpdateAdmin, {loading, data}] = useMutation(UPDATE_CLIENT)
  const submitForm = async (values) => {
    await UpdateAdmin({
      variables: {
        firstname: values.firstname,
        lastname: values.lastname,
        about: values.about,
        id: user.id,

        businessName: values.businessName,
        phone: values.phone,
        clientDescription: values.clientDescription,
        businessDescription: values.businessDescription,
        numberOfStores: values.numberOfStores?.toString(),
        specialInstructions: values.specialInstructions,
        shippingAddress: values.shippingAddress,
        billingAddress: values.billingAddress,
        website: values.website,
        password: values.password,
      },
    })
  }

  return (
    <div className="page-wrapper">
      <div className="gh-canvas-header">
        <div className="gh-canvas-header-content">
          <div className="flex flex-column">
            <div className="gh-canvas-breadcrumb">
              <Link to="/admins">Админ</Link>
              <svg viewBox="0 0 18 27">
                <path
                  d="M2.397 25.426l13.143-11.5-13.143-11.5"
                  strokeWidth="3"
                  stroke="#0B0B0A"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
              Засах
            </div>
            <h2 className="gh-canvas-title">
              <a href="/admins" className="active ">
                Админ засах
              </a>
            </h2>
          </div>
        </div>
      </div>
      <div className="position-relative">
        <SpinLoader loading={loading} absolute />
        {user && <UserForm submitForm={submitForm} user={user} />}
      </div>
    </div>
  )
}
