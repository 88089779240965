import React from "react"
import {Outlet} from "react-router-dom"
import Sidebar from "./Sidebar"
import Logo from "../assets/images/logo.png"
export default function MasterLayout() {

  return (
    <>
      <div className="gh-app">
        <div className="gh-view-port">
          <div className="gh-nav">
            <div className="d-flex flex-column h-100">
              <div className="gh-nav-menu">
                <div className="gh-nav-menu-details">
                  <img src={Logo} className="gh-nav-logo" />
                </div>
              </div>
              <div className="gh-nav-body">
                <div className="gh-nav-top">
                  <Sidebar />
                </div>
              </div>
            </div>
          </div>
          <div className="gh-main">
            <div className="w-100 gh-canvas">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
