import { toast } from "react-hot-toast"
import {Link} from "react-router-dom"

export default function DownloadRequestExcel({id}) {
  const getExcelFileName = async () => {
    const response = await fetch(process.env.REACT_APP_API_URL + "/api/download/excel/request/" + id).then((response) => response.json())
    if (!response) {
      toast.error("Not available for download")
    } else if (response?.file === false) {
      toast.error(response.message || "Not found")
    } else {
      window.open(process.env.REACT_APP_API_URL + "/excels/" + response.file)
    }
		// process.env.REACT_APP_API_URL+"/excels/"+response.file
  }

  return (
    <Link to="#_" className="btn btn-sm btn-light" onClick={getExcelFileName}>
      Excel татах
    </Link>
  )
}
