import React, {useEffect, useState} from "react"

import MediaScreen from "./MediaScreen"
import Modal from "../../components/Modal"
import {useModal} from "../../providers/Modal"

export default function MediaChooserFromLibrary({setMedia, multiple = false}) {
  const {modalId, showModal} = useModal()
  const [selected, setSelected] = useState([])

  const showMediaPopup = () => {
    showModal("mediaModal")
  }
  const selectMedia = (m) => {
    if (multiple) {
      if (selected?.length > 0 && selected?.filter((s) => s.id === m.id).length > 0) {
        // remove
        setSelected(selected?.filter((s) => s.id !== m.id))
      } else {
        // add
        setSelected([...selected, m])
      }
    } else {
      setSelected([m])
    }
  }
  const returnMedia = () => {
    setMedia(selected)
    showModal("")
  }
  useEffect(() => {
    if (!modalId) {
      setSelected([])
    }
    return () => {}
  }, [modalId])

  return (
    <>
      <a href="#_" onClick={showMediaPopup} className="fs-13  me-4 media-lib-link">
        Media library
      </a>

      <Modal full darkBackdrop id={`mediaModal`}>
        <Modal.Body>
          <div className="media-pp-wrap">
            <div className="media-pp-head">
              <h2 className="gh-canvas-title">
                <a href="/media" className="active ">
                  Медиа
                </a>
              </h2>
            </div>
            <div className="media-pp-list-wrap ">
              <MediaScreen selectMedia={selectMedia} selectedIds={selected?.length > 0 ? selected?.map((s) => s.id) : []} />
            </div>
            <div className="media-pp-toolbar">
              <div className="">
                <button onClick={returnMedia} className={`btn ${selected ? "btn-primary" : "btn-disabled"}`}>
                  Зураг оруулах
                </button>
              </div>
              <div className="">
                <button className="btn btn-light" onClick={() => showModal("")}>
                  Хаах
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
