import {Link, useLocation} from "react-router-dom"
import {useAuth} from "../providers/Auth"

export default function Sidebar() {
  let location = useLocation()
  const {removeAuth} = useAuth()

  return (
    <>
      <ul className="gh-nav-list gh-nav-main">
        <li className="nav-item">
          <Link className={`nav-link ${location.pathname === "/" ? "active" : ""}`} aria-current="page" to="/">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <g id="vuesax_linear_home-2" data-name="vuesax/linear/home-2" transform="translate(-620 -188)">
                <g id="home-2">
                  <path id="Vector" d="M7.02.823l-5.39,4.2A4.759,4.759,0,0,0,0,8.343v7.41a4.225,4.225,0,0,0,4.21,4.22H15.79A4.223,4.223,0,0,0,20,15.763V8.483a4.723,4.723,0,0,0-1.8-3.45L12.02.7A4.487,4.487,0,0,0,7.02.823Z" transform="translate(622 190.017)" fill="none" stroke="#292d32" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                  <path id="Vector-2" data-name="Vector" d="M0,3V0" transform="translate(632 202.99)" fill="none" stroke="#292d32" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                  <path id="Vector-3" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(620 188)" fill="none" opacity="0" />
                </g>
              </g>
            </svg>
            Дашбоард
          </Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${location.pathname.includes("/orders") ? "active" : ""}`} to="/orders">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <g id="vuesax_linear_bag" data-name="vuesax/linear/bag" transform="translate(-108 -188)">
                <g id="bag">
                  <path id="Vector" d="M3.62,0,0,3.63" transform="translate(113.19 190)" fill="none" stroke="#292d32" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                  <path id="Vector-2" data-name="Vector" d="M0,0,3.62,3.63" transform="translate(123.19 190)" fill="none" stroke="#292d32" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                  <path id="Vector-3" data-name="Vector" d="M0,2C0,.15.99,0,2.22,0H17.78C19.01,0,20,.15,20,2c0,2.15-.99,2-2.22,2H2.22C.99,4,0,4.15,0,2Z" transform="translate(110 193.85)" fill="none" stroke="#292d32" strokeWidth="1.5" />
                  <path id="Vector-4" data-name="Vector" d="M0,0V3.55" transform="translate(117.76 202)" fill="none" stroke="#292d32" strokeLinecap="round" strokeWidth="1.5" />
                  <path id="Vector-5" data-name="Vector" d="M0,0V3.55" transform="translate(122.36 202)" fill="none" stroke="#292d32" strokeLinecap="round" strokeWidth="1.5" />
                  <path id="Vector-6" data-name="Vector" d="M0,0,1.41,8.64C1.73,10.58,2.5,12,5.36,12h6.03c3.11,0,3.57-1.36,3.93-3.24L17,0" transform="translate(111.5 198)" fill="none" stroke="#292d32" strokeLinecap="round" strokeWidth="1.5" />
                  <path id="Vector-7" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(108 188)" fill="none" opacity="0" />
                </g>
              </g>
            </svg>
            Захиалга
          </Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${location.pathname === "/price-quotation" ? "active" : ""}`} to="/price-quotation">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="feather bi bi-patch-question" viewBox="0 0 16 16">
              <path d="M8.05 9.6c.336 0 .504-.24.554-.627.04-.534.198-.815.847-1.26.673-.475 1.049-1.09 1.049-1.986 0-1.325-.92-2.227-2.262-2.227-1.02 0-1.792.492-2.1 1.29A1.71 1.71 0 0 0 6 5.48c0 .393.203.64.545.64.272 0 .455-.147.564-.51.158-.592.525-.915 1.074-.915.61 0 1.03.446 1.03 1.084 0 .563-.208.885-.822 1.325-.619.433-.926.914-.926 1.64v.111c0 .428.208.745.585.745z" />
              <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z" />
              <path d="M7.001 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0z" />
            </svg>
            Үнийн санал
          </Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${location.pathname.includes("/products") ? "active" : ""}`} to="/products">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <g id="vuesax_linear_shopping-cart" data-name="vuesax/linear/shopping-cart" transform="translate(-622 -188)">
                <g id="shopping-cart">
                  <path id="Vector" d="M0,0H1.74A1.85,1.85,0,0,1,3.58,2l-.83,9.96a2.8,2.8,0,0,0,2.79,3.03H16.19A2.877,2.877,0,0,0,19,12.38l.54-7.5a2.773,2.773,0,0,0-2.81-3.01H3.82" transform="translate(624 190)" fill="none" stroke="#292d32" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                  <path id="Vector-2" data-name="Vector" d="M2.5,1.25A1.25,1.25,0,1,1,1.25,0,1.25,1.25,0,0,1,2.5,1.25Z" transform="translate(637 207.5)" fill="none" stroke="#292d32" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                  <path id="Vector-3" data-name="Vector" d="M2.5,1.25A1.25,1.25,0,1,1,1.25,0,1.25,1.25,0,0,1,2.5,1.25Z" transform="translate(629 207.5)" fill="none" stroke="#292d32" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                  <path id="Vector-4" data-name="Vector" d="M0,0H12" transform="translate(631 196)" fill="none" stroke="#292d32" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                  <path id="Vector-5" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(622 188)" fill="none" opacity="0" />
                </g>
              </g>
            </svg>
            Бүтээгдэхүүн
          </Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${location.pathname.includes("/tags") ? "active" : ""}`} to="/tags">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
              <g id="vuesax_linear_tag" data-name="vuesax/linear/tag" transform="translate(-556 -444)">
                <g id="tag" transform="translate(556 444)">
                  <path id="Vector" d="M.933,8.352l3.02,3.02a3.187,3.187,0,0,0,4.5,0L11.38,8.445a3.187,3.187,0,0,0,0-4.5L8.357.935a3.167,3.167,0,0,0-2.4-.927L2.623.168A2.576,2.576,0,0,0,.163,2.615L0,5.948a3.2,3.2,0,0,0,.93,2.4Z" transform="translate(1.843 1.848)" fill="none" stroke="#292d32" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                  <path id="Vector-2" data-name="Vector" d="M3.333,1.667A1.667,1.667,0,1,1,1.667,0,1.667,1.667,0,0,1,3.333,1.667Z" transform="translate(4.667 4.667)" fill="none" stroke="#292d32" strokeLinecap="round" strokeWidth="1" />
                  <path id="Vector-3" data-name="Vector" d="M0,0H16V16H0Z" fill="none" opacity="0" />
                </g>
              </g>
            </svg>
            Таггууд
          </Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${location.pathname.includes("/consumers") ? "active" : ""}`} to="/consumers">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="feather bi bi-people" viewBox="0 0 16 16">
              <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8Zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816ZM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z" />
            </svg>
            Харилцагчид
          </Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${location.pathname.includes("/posts") ? "active" : ""}`} to="/posts">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <g id="vuesax_linear_edit" data-name="vuesax/linear/edit" transform="translate(-620 -252)">
                <g id="edit">
                  <path id="Vector" d="M9,0H7C2,0,0,2,0,7v6c0,5,2,7,7,7h6c5,0,7-2,7-7V11" transform="translate(622 254)" fill="none" stroke="#292d32" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                  <path id="Vector-2" data-name="Vector" d="M8.991,1.271l-7.88,7.88a2.712,2.712,0,0,0-.66,1.32l-.43,3.01a1.424,1.424,0,0,0,1.7,1.7l3.01-.43a2.8,2.8,0,0,0,1.32-.66l7.88-7.88c1.36-1.36,2-2.94,0-4.94S10.351-.089,8.991,1.271Z" transform="translate(627.049 253.749)" fill="none" stroke="#292d32" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                  <path id="Vector-3" data-name="Vector" d="M0,0A7.144,7.144,0,0,0,4.94,4.94" transform="translate(634.91 256.15)" fill="none" stroke="#292d32" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                  <path id="Vector-4" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(620 252)" fill="none" opacity="0" />
                </g>
              </g>
            </svg>
            Нийтлэл
          </Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${location.pathname.includes("/media") ? "active" : ""}`} to="/media">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
              <g id="vuesax_linear_folder-2" data-name="vuesax/linear/folder-2" transform="translate(-428 -188)">
                <g id="folder-2" transform="translate(428 188)">
                  <path id="Vector" d="M13.333,6v4c0,2.667-.667,3.333-3.333,3.333H3.333C.667,13.333,0,12.667,0,10V3.333C0,.667.667,0,3.333,0h1a1.574,1.574,0,0,1,1.6.8l1,1.333A1.056,1.056,0,0,0,8,2.667h2C12.667,2.667,13.333,3.333,13.333,6Z" transform="translate(1.333 1.333)" fill="none" stroke="#292d32" strokeWidth="1" />
                  <path id="Vector-2" data-name="Vector" d="M0,0H6A1.768,1.768,0,0,1,8,2v.92" transform="translate(5.333 1.333)" fill="none" stroke="#292d32" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                  <path id="Vector-3" data-name="Vector" d="M0,0H16V16H0Z" transform="translate(16 16) rotate(180)" fill="none" opacity="0" />
                </g>
              </g>
            </svg>
            Медиа
          </Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${location.pathname.includes("/product-photo-sort") ? "active" : ""}`} to="/product-photo-sort">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <g id="sort" transform="translate(-172 -316)">
                <path id="Vector" d="M0,0H18" transform="translate(175 323)" fill="none" stroke="#292d32" strokeLinecap="round" strokeWidth="1.5" />
                <path id="Vector-2" data-name="Vector" d="M0,0H12" transform="translate(178 328)" fill="none" stroke="#292d32" strokeLinecap="round" strokeWidth="1.5" />
                <path id="Vector-3" data-name="Vector" d="M0,0H4" transform="translate(182 333)" fill="none" stroke="#292d32" strokeLinecap="round" strokeWidth="1.5" />
                <path id="Vector-4" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(172 316)" fill="none" opacity="0" />
              </g>
            </svg>
            Эрэмбэ
          </Link>
        </li>
      </ul>

      <ul className="gh-nav-list gh-nav-main">
        <li className="nav-item">
          <Link className={`nav-link ${location.pathname.includes("/admins") ? "active" : ""}`} to="/admins">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <g id="vuesax_linear_security-user" data-name="vuesax/linear/security-user" transform="translate(-748 -252)">
                <g id="security-user">
                  <g id="Group">
                    <path id="Vector" d="M7.08.233,2.09,2.1A3.472,3.472,0,0,0,0,5.122v7.43a4.862,4.862,0,0,0,1.73,3.44l4.3,3.21a4.552,4.552,0,0,0,5.14,0l4.3-3.21a4.862,4.862,0,0,0,1.73-3.44V5.122A3.472,3.472,0,0,0,15.11,2.1L10.12.233A5.085,5.085,0,0,0,7.08.233Z" transform="translate(751.41 253.997)" fill="none" stroke="#292d32" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                  </g>
                  <g id="Group-2" data-name="Group">
                    <path id="Vector-2" data-name="Vector" d="M1.82,3.52H1.69a1.765,1.765,0,1,1,.13,0Z" transform="translate(758.18 259.4)" fill="none" stroke="#292d32" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    <path id="Vector-3" data-name="Vector" d="M.72.548a1.3,1.3,0,0,0,0,2.33,3.872,3.872,0,0,0,3.97,0,1.3,1.3,0,0,0,0-2.33A3.872,3.872,0,0,0,.72.548Z" transform="translate(757.29 265.173)" fill="none" stroke="#292d32" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                  </g>
                  <path id="Vector-4" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(748 252)" fill="none" opacity="0" />
                </g>
              </g>
            </svg>
            Админ
          </Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${location.pathname.includes("/settings") ? "active" : ""}`} to="/settings">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <g id="vuesax_linear_setting-2" data-name="vuesax/linear/setting-2" transform="translate(-300 -188)">
                <g id="setting-2">
                  <path id="Vector" d="M6,3A3,3,0,1,1,3,0,3,3,0,0,1,6,3Z" transform="translate(309 197)" fill="none" stroke="#292d32" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                  <path
                    id="Vector-2"
                    data-name="Vector"
                    d="M0,10.319V8.559a1.906,1.906,0,0,1,1.9-1.9c1.81,0,2.55-1.28,1.64-2.85a1.9,1.9,0,0,1,.7-2.59L5.97.229a1.669,1.669,0,0,1,2.28.6l.11.19c.9,1.57,2.38,1.57,3.29,0l.11-.19a1.669,1.669,0,0,1,2.28-.6l1.73.99a1.9,1.9,0,0,1,.7,2.59c-.91,1.57-.17,2.85,1.64,2.85a1.906,1.906,0,0,1,1.9,1.9v1.76a1.906,1.906,0,0,1-1.9,1.9c-1.81,0-2.55,1.28-1.64,2.85a1.9,1.9,0,0,1-.7,2.59l-1.73.99a1.669,1.669,0,0,1-2.28-.6l-.11-.19c-.9-1.57-2.38-1.57-3.29,0l-.11.19a1.669,1.669,0,0,1-2.28.6l-1.73-.99a1.9,1.9,0,0,1-.7-2.59c.91-1.57.17-2.85-1.64-2.85A1.906,1.906,0,0,1,0,10.319Z"
                    transform="translate(302 190.561)"
                    fill="none"
                    stroke="#292d32"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                  />
                  <path id="Vector-3" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(300 188)" fill="none" opacity="0" />
                </g>
              </g>
            </svg>
            Тохиргоо
          </Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${location.pathname === "/logs" ? "active" : ""}`} to="/logs">
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="feather bi bi-file-earmark-lock" viewBox="0 0 16 16">
              <path d="M10 7v1.076c.54.166 1 .597 1 1.224v2.4c0 .816-.781 1.3-1.5 1.3h-3c-.719 0-1.5-.484-1.5-1.3V9.3c0-.627.46-1.058 1-1.224V7a2 2 0 1 1 4 0zM7 7v1h2V7a1 1 0 0 0-2 0zM6 9.3v2.4c0 .042.02.107.105.175A.637.637 0 0 0 6.5 12h3a.64.64 0 0 0 .395-.125c.085-.068.105-.133.105-.175V9.3c0-.042-.02-.107-.105-.175A.637.637 0 0 0 9.5 9h-3a.637.637 0 0 0-.395.125C6.02 9.193 6 9.258 6 9.3z" />
              <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
            </svg> */}
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <g id="vuesax_linear_filter-search" data-name="vuesax/linear/filter-search" transform="translate(-492 -700)">
                <g id="filter-search">
                  <g id="Group">
                    <path id="Vector" d="M11.01,17.06a2.246,2.246,0,0,1-.91,1.72l-1.41.91a2.058,2.058,0,0,1-3.13-1.72V12.62a3.834,3.834,0,0,0-.81-2.12L.91,6.46A3.323,3.323,0,0,1,0,4.44V2.12A2.051,2.051,0,0,1,2.02,0H15.36a2.027,2.027,0,0,1,2.02,2.02V4.24a3.773,3.773,0,0,1-1.01,2.32" transform="translate(495.31 702.01)" fill="none" stroke="#292d32" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                  </g>
                  <g id="Group-2" data-name="Group">
                    <path id="Vector-2" data-name="Vector" d="M6.4,3.2A3.2,3.2,0,1,1,3.2,0,3.2,3.2,0,0,1,6.4,3.2Z" transform="translate(504.87 710.12)" fill="none" stroke="#292d32" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    <path id="Vector-3" data-name="Vector" d="M1,1,0,0" transform="translate(510.87 716.12)" fill="none" stroke="#292d32" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                  </g>
                  <path id="Vector-4" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(516 724) rotate(180)" fill="none" opacity="0" />
                </g>
              </g>
            </svg>
            Лог
          </Link>
        </li>
      </ul>

      <ul className="gh-nav-list gh-nav-main">
        <li className="nav-item">
          <Link className={`nav-link`} to="#_" onClick={() => removeAuth()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <defs>
                <clipPath id="clip-path">
                  <rect width="24" height="24" fill="none" />
                </clipPath>
              </defs>
              <g id="vuesax_linear_logout" data-name="vuesax/linear/logout" clip-path="url(#clip-path)">
                <g id="vuesax_linear_logout-2" data-name="vuesax/linear/logout" transform="translate(-748 -444)">
                  <g id="logout">
                    <path id="Vector" d="M0,5.07C.31,1.47,2.16,0,6.21,0h.13c4.47,0,6.26,1.79,6.26,6.26v6.52c0,4.47-1.79,6.26-6.26,6.26H6.21c-4.02,0-5.87-1.45-6.2-4.99" transform="translate(756.9 446.49)" fill="none" stroke="#292d32" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                    <path id="Vector-2" data-name="Vector" d="M11.38,0H0" transform="translate(751.62 456)" fill="none" stroke="#292d32" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                    <path id="Vector-3" data-name="Vector" d="M3.35,0,0,3.35,3.35,6.7" transform="translate(750.5 452.65)" fill="none" stroke="#292d32" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                    <path id="Vector-4" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(772 468) rotate(180)" fill="none" opacity="0" />
                  </g>
                </g>
              </g>
            </svg>
            Гарах
          </Link>
        </li>
      </ul>
    </>
  )
}
