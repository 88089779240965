import {gql} from "@apollo/client"
export const GET_TERM_LIST = gql`
  query GetTermList {
    GetTermList
  }
`
export const GET_TERM_DETAIL = gql`
  query GetTermDetail($id: Int!) {
    GetTermDetail(id: $id)
  }
`
