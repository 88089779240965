import {useQuery} from "@apollo/client"
import {Link} from "react-router-dom"
import {GET_DASH_INFO} from "./graphql/query"
import {useState} from "react"

export default function DashScreen() {
  const [info, setInfo] = useState()
  const {loading} = useQuery(GET_DASH_INFO, {
    onCompleted: (data) => {
      setInfo(data.GetDashInfo)
    },
  })

  // console.log("dash data", info)
  let orderAll = 0,
    requestAll = 0
  if (info) {
    info.order.forEach((o) => {
      orderAll += o._count._all
    })
    info.request.forEach((o) => {
      requestAll += o._count._all
    })
  }
  return (
    <>
      <div className="page-wrapper">
        <div className="gh-canvas-header">
          <div className="gh-canvas-header-content">
            <div className="flex flex-column">
              <h2 className="gh-canvas-title">
                <a href="/admins" className="active ">
                  Дашбоард
                </a>
              </h2>
            </div>
            <div className="view-actions">
              <Link to="/consumers/add" type="button" className="btn  btn-dark me-2">
                Шинэ харилцагч
              </Link>
              <Link to="/products/add" type="button" className="btn  btn-dark">
                Шинэ бүтээгдэхүүн
              </Link>
            </div>
          </div>
        </div>
        <div>
          {info && (
            <>
              <div className="dash-row">
                <div className="dash-col">
                  <Link to={"/orders"} className="dash-box">
                    <div className="db-title">Захиалга</div>
                    <div className="db-content">
                      <p>
                        Бүгд: <b>{orderAll}</b>
                      </p>
                      <p>
                        Хүлээгдэж байгаа: <b>{info.order.filter((o) => o.status === "pending")[0]?._count._all || 0}</b>
                      </p>
                      <p>
                        Идэвхитэй: <b>{info.order.filter((o) => o.status === "active")[0]?._count._all || 0}</b>
                      </p>
                      <p>
                        Амжилттай дууссан: <b>{info.order.filter((o) => o.status === "completed")[0]?._count._all || 0}</b>
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="dash-col">
                  <Link to={"/price-quotation"} className="dash-box">
                    <div className="db-title">Үнийн санал</div>
                    <div className="db-content">
                      <p>
                        Бүгд: <b>{requestAll}</b>
                      </p>
                      <p>
                        Хүлээгдэж байгаа: <b>{info.request.filter((o) => o.status === "pending")[0]?._count._all || 0}</b>
                      </p>
                      <p>
                        Идэвхитэй: <b>{info.request.filter((o) => o.status === "active")[0]?._count._all || 0}</b>
                      </p>
                      <p>
                        Амжилттай дууссан: <b>{info.request.filter((o) => o.status === "completed")[0]?._count._all || 0}</b>
                      </p>
                    </div>
                  </Link>
                </div>
         
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}
