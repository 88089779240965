import {gql} from "@apollo/client"

export const UPDATE_ORDER_STATUS = gql`
  mutation UpdateOrderStatus($status: String!, $id: Int!) {
    UpdateOrderStatus(status: $status, id: $id)
  }
`
export const DELETE_ORDER = gql`
  mutation DeleteOrderByAdmin($id: Int!) {
    DeleteOrderByAdmin(id: $id)
  }
`