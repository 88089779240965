import React from "react"
import {useInit} from "../../providers/Init"

import {useModal} from "../../providers/Modal"
import Modal from "../../components/Modal"
import SpinLoader from "../../components/loaders/SpinLoader"
import {useForm} from "react-hook-form"
import {ADD_PRICE_GROUP} from "./graphql/mutation"
import {useMutation} from "@apollo/client"
import GroupItem from "./GroupItem"
import TermsScreen from "../term/TermsScreen"
import {Link} from "react-router-dom"

export default function SettingsScreen() {
  const {settings} = useInit()
  const {modalId, showModal} = useModal()
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm()
  const [AddGroup, {loading, data}] = useMutation(ADD_PRICE_GROUP)

  const submitGroupForm = async (values) => {
    const response = await AddGroup({
      variables: {
        name: values.name,
        currencyId: values.currencyId,
        exchange_price: parseInt(values.exchange_price),
      },
      refetchQueries: ["GetSettings"],
    })
    showModal("")
    // console.log("submitGroupForm response:", response)
  }
  return (
    <div className="page-wrapper">
      <div className="gh-canvas-header">
        <div className="gh-canvas-header-content">
          <div className="flex flex-column">
            <h2 className="gh-canvas-title">Тохиргоо</h2>
          </div>
          <div className="view-actions">
            {/* <Link to="/consumers/add" type="button" className="btn  btn-dark">
              Хэрэглэгч нэмэх
            </Link> */}
          </div>
        </div>
      </div>

      <div className="">
        <br />
        <br />
        <div className="row mb-3">
          <div className="col">
            <h5>Үнийн зэрэглэл</h5>
          </div>
          <div className="col text-end">
            <a href="#_" onClick={() => showModal("addGroupModal")} className="fs-13 ms-auto media-lib-link">
              Шинэ үүсгэх
            </a>
          </div>
        </div>

        <Modal darkBackdrop id={`addGroupModal`}>
          <Modal.Body>
            {/* <SpinLoader loading={updateLoading || deleteLoading} absolute /> */}
            <form onSubmit={handleSubmit(submitGroupForm)}>
              <div className="mb-3">
                <label className="form-label">Нэр</label>
                <input type="text" className="form-control" {...register("name", {required: true})} />
              </div>

              <div className="mb-3">
                <label className="form-label">Валют</label>
                <select className="form-control" {...register("currencyId", {required: true})}>
                  {settings?.currency?.map((c, key) => {
                    return (
                      <option value={c.id} key={key}>
                        {c.id}
                      </option>
                    )
                  })}
                </select>
              </div>
              <div className="mb-3">
                <label className="form-label">Ханш</label>
                <input type="text" className="form-control" {...register("exchange_price", {required: true})} />
              </div>

              {/* <div className="mb-3">
                <label className="form-label">Хямдрал (%)</label>
                <input type="number" className="form-control" {...register("sale", {required: false})} />
              </div> */}

              <div className="mt-3 d-flex justify-content-between">
                <button type="submit" className="btn btn-dark btn-lg">
                  Хадгалах
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <div className="table-responsive">
          <table className="table align-middle mb-0 bg-white ">
            <thead className="bg-light">
              <tr>
                <th scope="col" className="">
                  Харилцагчийн зэрэг, ханш
                </th>

                <th></th>
              </tr>
            </thead>
            <tbody>
              {settings.price_group?.map((p, key) => {
                return <GroupItem item={p} key={key} />
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div className="">
        <br />
        <br />
        <div className="row mb-3">
          <div className="col">
            <h5>Үйлчилгээний нөхцөл</h5>
          </div>
          <div className="col text-end">
            <Link to="/settings/term/new" className="fs-13  me-4 media-lib-link">
              Шинэ үүсгэх
            </Link>
          </div>
        </div>

        <TermsScreen />
      </div>

      <div className="">
        <br />
        <br />

        <div className="row mb-3">
          <div className="col">
            <h5>Валют</h5>
          </div>
          <div className="col text-end"></div>
        </div>
        <div className="table-responsive">
          <table className="table align-middle mb-0 bg-white ">
            <thead className="bg-light">
              <tr>
                <th scope="col" className="">
                  ID
                </th>
              </tr>
            </thead>
            <tbody>
              {settings?.currency?.map((c, key) => {
                return (
                  <tr key={key}>
                    <td scope="col" className="">
                      {c.id}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
