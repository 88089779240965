import {gql} from "@apollo/client"

export const ADD_PRICE_GROUP = gql`
  mutation AddPriceGroup($name: String!, $currencyId: String!, $exchange_price: Int!, $sale: Int) {
    AddPriceGroup(name: $name, currencyId: $currencyId, exchange_price: $exchange_price, sale: $sale)
  }
`
export const UPDATE_PRICE_GROUP = gql`
  mutation UpdatePriceGroup($id: Int!, $name: String!, $currencyId: String!, $exchange_price: Int!, $sale: Int) {
    UpdatePriceGroup(id: $id, name: $name, currencyId: $currencyId, exchange_price: $exchange_price, sale: $sale)
  }
`
