import {gql} from "@apollo/client"

export const GET_ME = gql`
  query Admin {
    admin {
      user {
        id
        email
        status
        createdAt
        profile {
          firstname
          lastname
          businessName
          phone
          clientDescription
          businessDescription
          numberOfStores
          specialInstructions
          shippingAddress
          billingAddress
          website
        }
      }
    }
  }
`

export const GET_PHOTO_LIST = gql`
  query GetPhotoList($skip: Int, $categoryId: Int, $search: String) {
    GetPhotoList(skip: $skip, categoryId: $categoryId, search: $search) {
      edges {
        cursor
        node {
          id
          status
          order
          mediaId
          productId
          product {
            id
            status
            name
            code
            gauge
            ply
            description
            updatedAt
            createdAt
            userId
            thumbnailId
          }
          media {
            id
            fileName
            filePath
            fileFolder
            fileSize
            fileType
            fileExt
            createdAt
            userId
            mediaImageGenerateds {
              id
              path
              generatedID
              fileFolder
              fileExtension
              fileSize
              sizeName
              sizeWidth
              sizeHeight
              forRetina
              mediaId
              createdAt
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`
export const GET_FOLDER_PHOTO_LIST = gql`
  query GetFolderPhotoList($skip: Int, $folderId: Int) {
    GetFolderPhotoList(skip: $skip, folderId: $folderId) {
      edges {
        cursor
        node {
          id
          status
          order
          mediaId
          productId
          product {
            id
            status
            name
            description
            updatedAt
            createdAt
            userId
            thumbnailId
          }
          media {
            id
            fileName
            filePath
            fileFolder
            fileSize
            fileType
            fileExt
            createdAt
            userId
            mediaImageGenerateds {
              id
              path
              generatedID
              fileFolder
              fileExtension
              fileSize
              sizeName
              sizeWidth
              sizeHeight
              forRetina
              mediaId
              createdAt
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`
export const GET_FOLDER_LIST = gql`
  query GetFolderList($skip: Int) {
    GetFolderList(skip: $skip) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          name
          updatedAt
          createdAt
          userId
          photos {
            id
            createdAt
            folderId
            photo {
              id
              status
              order
              mediaId
              productId
              media {
                id
                fileName
                filePath
                fileFolder
                fileSize
                fileType
                fileExt
                createdAt
                userId
                mediaImageGenerateds {
                  id
                  path
                  generatedID
                  fileFolder
                  fileExtension
                  fileSize
                  sizeName
                  sizeWidth
                  sizeHeight
                  forRetina
                  mediaId
                  createdAt
                }
              }
            }
          }
        }
      }
    }
  }
`
export const GET_REQUEST_LIST = gql`
  query GetRequestList($skip: Int) {
    GetRequestList(skip: $skip) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          status
          updatedAt
          createdAt
          userId
          products {
            id
            createdAt
            productId
            requestId
            userId
            comment
            product {
              id
              status
              name
              description
              updatedAt
              createdAt
              userId
              thumbnailId
              thumbnail {
                id
                fileName
                filePath
                fileFolder
                fileSize
                fileType
                fileExt
                createdAt
                userId
                mediaImageGenerateds {
                  id
                  path
                  generatedID
                  fileFolder
                  fileExtension
                  fileSize
                  sizeName
                  sizeWidth
                  sizeHeight
                  forRetina
                  mediaId
                  createdAt
                }
              }
            }
          }
        }
      }
    }
  }
`
export const GET_REQUEST_DETAIL = gql`
  query GetRequestDetail($id: Int!) {
    GetRequestDetail(id: $id) {
      id
      status
      updatedAt
      createdAt
      userId
      products {
        id
        createdAt
        productId
        requestId
        userId
        comment
        product {
          id
          status
          name
          description
          updatedAt
          createdAt
          userId
          thumbnailId
          thumbnail {
            id
            fileName
            filePath
            fileFolder
            fileSize
            fileType
            fileExt
            createdAt
            userId
            mediaImageGenerateds {
              id
              path
              generatedID
              fileFolder
              fileExtension
              fileSize
              sizeName
              sizeWidth
              sizeHeight
              forRetina
              mediaId
              createdAt
            }
          }
        }
      }
    }
  }
`

export const GET_ORDER_LIST = gql`
  query GetOrderList($skip: Int) {
    GetOrderList(skip: $skip) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          status
          updatedAt
          createdAt
          userId
          products {
            id
            createdAt
            productId
            orderId
            userId
            comment
            product {
              id
              status
              name
              description
              updatedAt
              createdAt
              userId
              thumbnailId
              thumbnail {
                id
                fileName
                filePath
                fileFolder
                fileSize
                fileType
                fileExt
                createdAt
                userId
                mediaImageGenerateds {
                  id
                  path
                  generatedID
                  fileFolder
                  fileExtension
                  fileSize
                  sizeName
                  sizeWidth
                  sizeHeight
                  forRetina
                  mediaId
                  createdAt
                }
              }
            }
          }
        }
      }
    }
  }
`
export const GET_ORDER_DETAIL = gql`
  query GetOrderDetail($id: Int!) {
    GetOrderDetail(id: $id) {
      id
      status
      updatedAt
      createdAt
      userId
      products {
        id
        createdAt
        productId
        orderId
        userId
        comment
        OrderProductsAttribute {
          id
          count
          orderProductsId
          attributeId
        }
        product {
          id
          status
          name
          description
          updatedAt
          createdAt
          userId
          thumbnailId
          thumbnail {
            id
            fileName
            filePath
            fileFolder
            fileSize
            fileType
            fileExt
            createdAt
            userId
            mediaImageGenerateds {
              id
              path
              generatedID
              fileFolder
              fileExtension
              fileSize
              sizeName
              sizeWidth
              sizeHeight
              forRetina
              mediaId
              createdAt
            }
          }
        }
      }
    }
  }
`

export const GET_TAG_GROUPS = gql`
  query GetTagGroups {
    GetTagGroups {
      id
      type
      slug
      name
      tags {
        id
        type
        name
        code
        description
        order
      }
    }
  }
`
export const GET_TAGS = gql`
  query GetTags($groupIds: [Int]) {
    GetTags(groupIds: $groupIds) {
      id
      type
      name
      code
      description
      order
    }
  }
`
