import {useMutation, useQuery} from "@apollo/client"
import {DELETE_POST, UPDATE_POST} from "./graphql/mutation"
import Cookies from "universal-cookie"
import SpinLoader from "../../components/loaders/SpinLoader"
import {toast} from "react-hot-toast"
import {Link, useNavigate, useParams} from "react-router-dom"
// import {GET_TAG} from "./graphql/query"
import {useInit} from "../../providers/Init"
import PostForm from "./form/PostForm"
import Modal from "../../components/Modal"
import {useModal} from "../../providers/Modal"
import {GET_POST} from "./graphql/query"
const cookies = new Cookies()

export default function EditPostScreen() {
  const params = useParams()
  const navigate = useNavigate()
  const {modalId, showModal} = useModal()
  const [UpdatePost, {loading: updateLoading}] = useMutation(UPDATE_POST)
  const [DeletePost, {loading: deleteLoading}] = useMutation(DELETE_POST)

  const {data, loading} = useQuery(GET_POST, {
    variables: {
      id: parseInt(params.id),
    },
    fetchPolicy: "no-cache",
  })

  const submitForm = async (values) => {
    values.id = parseInt(params.id)
    if (!values?.thumbnailId) delete values.thumbnailId
    await UpdatePost({
      variables: values,
      onCompleted: (data) => {
        toast.success("Хадгалдлаа")
      },
    })
  }

  return (
    <div className="">
      <div className="gh-canvas-header">
        <div className="gh-canvas-header-content">
          <div className="flex flex-column">
            <h2 className="gh-canvas-title">
              <a href="/posts" className="active ">
                Нийтлэл засах
              </a>
            </h2>
          </div>
          <div className="view-actions">
            {data?.GetPostDetail?.id && (
              <button onClick={() => showModal(`deletePostModal${data?.GetPostDetail?.id}`)} type="button" className="btn  btn-danger">
                Устгах
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="position-relative">
        <SpinLoader loading={loading || updateLoading} absolute small />
        {data?.GetPostDetail && <PostForm submitForm={submitForm} post={data.GetPostDetail} />}
      </div>
      {data?.GetPostDetail && (
        <>
          <Modal id={`deletePostModal${data?.GetPostDetail?.id}`}>
            <Modal.Body>
              <SpinLoader loading={deleteLoading} absolute />
              <h4>Устгах уу?</h4>

              <div className="d-flex align-items-center justify-content-between mt-4">
                <div className="d-flex align-items-center justify-content-start">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      DeletePost({
                        variables: {
                          id: data.GetPostDetail.id,
                        },
                        onCompleted: () => {
                          toast.success("Устгагдлаа")
                          showModal("")
                          navigate("/posts")
                        },
                      })
                    }}
                  >
                    Устгах
                  </button>
                  <button className="btn btn-light ms-2" onClick={() => showModal("")}>
                    Болих
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </div>
  )
}
