import {useMutation} from "@apollo/client"
import React from "react"
import {useForm} from "react-hook-form"
import {toast} from "react-hot-toast"
import {Navigate} from "react-router-dom"
import SpinLoader from "../../components/loaders/SpinLoader"
import {SIGNIN} from "../../graphql/mutation"
import {useAuth} from "../../providers/Auth"

export default function LoginForm(props) {
  const {register, handleSubmit, setValue} = useForm()
  const [Login, {loading, data}] = useMutation(SIGNIN)
  const {setAuth} = useAuth()

  const onSubmit = async (data) => {
    const response = await Login({
      variables: {
        username: data.username,
        password: data.password,
      },
    })

    if (response?.data?.AdminSignin?.token) {
      setAuth(response.data)
    }
  }

  return (
    <div className="">
      <SpinLoader loading={loading} absolute />
      <div className="login-form-wrap">
        <form onSubmit={handleSubmit(onSubmit)} className="pos-rel">
          <div className="form-group mb-3">
            <div className="  ">
              <label for="email">Email address *</label>
              <input type={"email"} {...register("username")} className="form-control" />
            </div>
          </div>
          <div className="form-group mb-4">
            <div className="  ">
              <label for="password">Password *</label>
              <input type={"password"} {...register("password")} className="form-control" />
            </div>
          </div>

          <button type="submit" className="btn btn-dark block" style={{paddingLeft: "30px", paddingRight: "30px"}}>
            Login
          </button>
        </form>
      </div>
    </div>
  )
}
