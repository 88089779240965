import React, {useEffect, useState} from "react"
import {useInit} from "../../providers/Init"

import {useModal} from "../../providers/Modal"
import SpinLoader from "../../components/loaders/SpinLoader"
import {useForm} from "react-hook-form"
import {ADD_PRICE_GROUP} from "./graphql/mutation"
import {useMutation} from "@apollo/client"
import axios from "axios"

export default function GenerateImageScreen() {
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [completed, setCompleted] = useState([])
  const [id, setID] = useState()
  const fetchPhotoIDS = async (e) => {
    const response = await axios.get(process.env.REACT_APP_API_URL + "/api/generate-product-image/variantion-photos")
    if (response?.status === 200) {
      if (response.data.length > 0) {
        setList(response.data)
        setID(response.data[0])
      }
    }
  }
  const resizeProductImage = async (id) => {
    const response = await axios.get(process.env.REACT_APP_API_URL + "/api/generate-product-image/" + id)
    if (response?.status === 200) {
      console.log("resized:", response.data)
    }
    setCompleted([...completed, id])
    let listWithoutID = list.filter((i) => i !== id)
    setList([...listWithoutID])
    if (listWithoutID?.length > 0) {
      setID(listWithoutID[0])
    }
  }
  useEffect(() => {
    if (id) {
      resizeProductImage(id)
    }
    return () => {}
  }, [id])
  return (
    <div className="page-wrapper">
      <div className="">
        <br />
        <div className="d-flex">
          <h5>Product</h5>
          <button className="btn btn-dark" onClick={fetchPhotoIDS}>
            Load
          </button>
        </div>
        <hr />
        <p>Uncomleted: {list.length}</p>
        <p>Completed: {completed.length}</p>
     

        <br />
        <br />
      </div>
    </div>
  )
}
