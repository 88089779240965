import React from "react"
import {createBrowserRouter} from "react-router-dom"
import MasterLayout from "../layouts/MasterLayout"
import AddAdmin from "../pages/AddAdmin"
import AddConsumer from "../pages/AddConsumer"
import AddProduct from "../pages/AddProduct"
import Admin from "../pages/Admin"
import Consumer from "../pages/Consumer"
import Dashboard from "../pages/Dashboard"
import EditAdmin from "../pages/EditAdmin"
import EditProduct from "../pages/EditProduct"
import Log from "../pages/Log"
import Login from "../pages/Login"
import Products from "../pages/Products"
import ProtectedRoute from "./protected"
import EditConsumer from "../pages/EditConsumer"
import NotFound from "../pages/NotFound"
import PriceQuotation from "../pages/PriceQuotation"
import Order from "../pages/Order"
import Tags from "../pages/Tags"
import AddTag from "../pages/AddTag"
import EditTag from "../pages/EditTag"
import Media from "../pages/Media"
import Posts from "../pages/Posts"
import AddPost from "../pages/AddPost"
import EditPost from "../pages/EditPost"
import Settings from "../pages/Settings"
import GenerateImages from "../pages/GenerateImages"
import ProductPhotoSort from "../pages/ProductPhotoSort"
import AddTermScreen from "../modules/term/AddTermScreen"
import EditTermScreen from "../modules/term/EditTermScreen"
import InitProviderWrap from "../providers/InitWrap"

export default createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <InitProviderWrap>
          <MasterLayout />
        </InitProviderWrap>
      </ProtectedRoute>
    ),
    children: [
      {
        path: "/",
        element: <Dashboard />,
      },
      {
        path: "/products",
        element: <Products />,
      },
      {
        path: "/products/add",
        element: <AddProduct />,
      },
      {
        path: "/products/edit/:id",
        element: <EditProduct />,
      },
      {
        path: "/logs",
        element: <Log />,
      },
      {
        path: "/consumers",
        element: <Consumer />,
      },
      {
        path: "/consumers/add",
        element: <AddConsumer />,
      },
      {
        path: "/consumers/edit/:id",
        element: <EditConsumer />,
      },
      {
        path: "/admins",
        element: <Admin />,
      },
      {
        path: "/admins/add",
        element: <AddAdmin />,
      },
      {
        path: "/admins/edit/:id",
        element: <EditAdmin />,
      },
      {
        path: "/price-quotation",
        element: <PriceQuotation />,
      },
      {
        path: "/orders",
        element: <Order />,
      },
      {
        path: "/tags",
        element: <Tags />,
      },
      {
        path: "/tags/add",
        element: <AddTag />,
      },
      {
        path: "/tags/edit/:id",
        element: <EditTag />,
      },
      {
        path: "/posts",
        element: <Posts />,
      },
      {
        path: "/posts/add",
        element: <AddPost />,
      },
      {
        path: "/posts/edit/:id",
        element: <EditPost />,
      },
      {
        path: "/media",
        element: <Media />,
      },
      {
        path: "/product-photo-sort",
        element: <ProductPhotoSort />,
      },
      {
        path: "/settings",
        element: <Settings />,
      },
      {
        path: "/settings/term/new",
        element: <AddTermScreen />,
      },
      {
        path: "/settings/term/:id",
        element: <EditTermScreen />,
      },
      {
        path: "/settings",
        element: <Settings />,
      },
      {
        path: "/settings/generate-image",
        element: <GenerateImages />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
])
