import MediaScreen from "../modules/media/MediaScreen"

export default function Media() {
  return (
    <div className="page-wrapper">
      <div className="gh-canvas-header">
        <div className="gh-canvas-header-content">
          <div className="flex flex-column">
            <h2 className="gh-canvas-title">
              <a href="/media" className="active ">
                Медиа
              </a>
            </h2>
          </div>
        </div>
      </div>
      <MediaScreen deleteAble={true} />
    </div>
  )
}
