import {gql} from "@apollo/client"

export const SIGNIN = gql`
  mutation AdminSignin($username: String!, $password: String!) {
    AdminSignin(username: $username, password: $password) {
      token
      user {
        id
        email
        status
        createdAt
        profile {
          firstname
          lastname
          businessName
          phone
          clientDescription
          businessDescription
          numberOfStores
          specialInstructions
          shippingAddress
          billingAddress
          website
        }
      }
    }
  }
`
export const CREATE_FOLDER_PHOTO = gql`
  mutation CreateFolderPhoto($photoId: Int!, $folderId: Int!) {
    CreateFolderPhoto(photoId: $photoId, folderId: $folderId)
  }
`
export const CREATE_FOLDER = gql`
  mutation CreateFolder($name: String!, $photoIds: [Int]) {
    CreateFolder(name: $name, photoIds: $photoIds) {
      id
      name
      updatedAt
      createdAt
      userId
      # photos: [FolderPhotos]
      # _count: FolderCount
    }
  }
`
export const UPDATE_FOLDER = gql`
  mutation UpdateFolder($name: String!, $id: Int!) {
    UpdateFolder(name: $name, id: $id) {
      id
      name
      updatedAt
      createdAt
      userId
      # photos: [FolderPhotos]
      # _count: FolderCount
    }
  }
`
export const DELETE_FOLDER = gql`
  mutation DeleteFolder($id: Int!) {
    DeleteFolder(id: $id)
  }
`
export const ADD_PRODUCT_TO_REQUEST = gql`
  mutation AddProductToRequest($productId: Int!) {
    AddProductToRequest(productId: $productId)
  }
`
export const REMOVE_PRODUCT_FROM_REQUEST = gql`
  mutation RemoveProductToRequest($requestProductId: Int!) {
    RemoveProductToRequest(requestProductId: $requestProductId)
  }
`

export const ADD_PRODUCT_TO_ORDER = gql`
  mutation AddProductToOrder($productId: Int!) {
    AddProductToOrder(productId: $productId)
  }
`
export const REMOVE_PRODUCT_FROM_ORDER = gql`
  mutation RemoveProductToOrder($orderProductId: Int!) {
    RemoveProductToOrder(orderProductId: $orderProductId)
  }
`
export const UPDATE_USER_INFO = gql`
  mutation UpdateInfo($firstname: String!, $lastname: String!) {
    UpdateInfo(firstname: $firstname, lastname: $lastname) {
      id
      status
      email
      createdAt
      profile {
        id
        avatarId
        avatarUrls
        firstname
        lastname
        birthdayYear
        birthdayMonth
        birthdayDay
        about
        gender
        createdAt
        userId
      }
      tempPassword
      folder {
        id
        name
        updatedAt
        createdAt
        userId
        _count {
          photos
        }
      }
      folderPhotos {
        id
        createdAt
        folderId
        photo {
          id
          status
          order
          mediaId
          productId
          media {
            id
            fileName
            filePath
            fileFolder
            fileSize
            fileType
            fileExt
            createdAt
            userId
            mediaImageGenerateds {
              id
              path
              generatedID
              fileFolder
              fileExtension
              fileSize
              sizeName
              sizeWidth
              sizeHeight
              forRetina
              mediaId
              createdAt
            }
          }
        }
      }
    }
  }
`
export const SEND_REQUEST = gql`
  mutation SendRequest($id: Int!) {
    SendRequest(id: $id)
  }
`
export const SEND_ORDER = gql`
  mutation SendOrder($id: Int!) {
    SendOrder(id: $id)
  }
`
export const UPDATE_ORDER_PRODUCT_COMMENT = gql`
  mutation UpdateOrderProductComment($orderProductId: Int!, $comment: String) {
    UpdateOrderProductComment(orderProductId: $orderProductId, comment: $comment)
  }
`
export const UPDATE_REQUEST_PRODUCT_COMMENT = gql`
  mutation UpdateRequestProductComment($requestProductId: Int!, $comment: String) {
    UpdateRequestProductComment(requestProductId: $requestProductId, comment: $comment)
  }
`
export const UPDATE_ORDER_PRODUCT_ATTRIBUTE = gql`
  mutation UpdateOrderProductAttribute($orderProductId: Int!, $attributeId: Int!, $count: Int!) {
    UpdateOrderProductAttribute(orderProductId: $orderProductId, attributeId: $attributeId, count: $count) {
      id
      count
      orderProductsId
      attributeId
    }
  }
`
