import { Link } from "react-router-dom";
import SettingsScreen from "../modules/settings/SettingsScreen";

export default function Settings() {
  return (
    <>
      <SettingsScreen />
    </>
  )
}
