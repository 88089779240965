import {gql} from "@apollo/client"
export const GET_PRODUCT_DETAIL = gql`
  query GetProductDetail($id: Int!) {
    GetProductDetail(id: $id) {
      id
      status
      name
      code
      gauge
      ply
      stitch
      size
      fit
      description
      thumbnailId
      updatedAt
      updatedById
      createdAt
      createdById
      variantions {
        id
        main_price
        wholesale_price
        productId
        tags {
          id
          variantionId
          tag {
            id
            type
            name
            code
            description
            order
          }
          tagId
        }
        photos {
          id
          mediaId
          isThumbnail
          mediaImageGenerateds
        }
      }

      product
    }
  }
`

export const GET_PRODUCT_LIST = gql`
  query GetProductListResponse($skip: Int, $search: String, $status: String) {
    GetProductListResponse(skip: $skip, search: $search, status: $status) {
      edges {
        cursor
        node {
          id
          status
          name
          code
          gauge
          ply
          stitch
          size
          fit
          description
          thumbnail
          thumbnailId
          updatedAt
          updatedById
          createdAt
          createdById
          variantions {
            id
            main_price
            wholesale_price
            productId
            tags {
              id
              variantionId
              tag {
                id
                type
                name
                code
                description
                order
              }
              tagId
            }
            photos {
              id
              isThumbnail
              photo
              mediaImageGenerateds
            }
            product_variantion_price
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`
