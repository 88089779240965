import {useForm} from "react-hook-form"
import {useModal} from "../../../providers/Modal"
import {DELETE_CLIENT} from "../graphql/mutation"
import {useMutation} from "@apollo/client"
import SpinLoader from "../../../components/loaders/SpinLoader"
import Modal from "../../../components/Modal"
import {useNavigate} from "react-router-dom"
import {toast} from "react-hot-toast"

export default function UserForm({submitForm, user}) {
  const {
    register,
    handleSubmit,
    watch,
    formState: {errors},
  } = useForm({
    defaultValues: {
      firstname: user?.profile.firstname,
      lastname: user?.profile.lastname,
      email: user?.email,
      businessName: user?.profile.businessName,
      phone: user?.profile.phone,
      clientDescription: user?.profile.clientDescription,
      businessDescription: user?.profile.businessDescription,
      numberOfStores: user?.profile.numberOfStores,
      specialInstructions: user?.profile.specialInstructions,
      shippingAddress: user?.profile.shippingAddress,
      billingAddress: user?.profile.billingAddress,
      website: user?.profile.website,
    },
  })
  const navigate = useNavigate()
  const {modalId, showModal} = useModal()
  const [DeleteClient, {loading: deleteLoading}] = useMutation(DELETE_CLIENT)
  // $firstname: String!
  // $lastname: String!
  // $email: String!
  // $password: String!
  // $businessName: String!
  // $phone: String!
  // $clientDescription: String!
  // $businessDescription: String!
  // $numberOfStores: String
  // $specialInstructions: String
  // $shippingAddress: String
  // $billingAddress: String
  // $website: String

  return (
    <div className="gh-main-section-content grey">
      <form onSubmit={handleSubmit(submitForm)}>
        <div className="row">
          <div className="col-md-8">
            <div className="mb-3">
              <label className="form-label">
                Firstname <span className="text-danger">*</span>
              </label>
              <input type="text" className="form-control" {...register("firstname", {required: true})} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="mb-3">
              <label className="form-label">
                Lastname <span className="text-danger">*</span>
              </label>
              <input type="text" className="form-control" {...register("lastname", {required: true})} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="mb-3">
              <label className="form-label">
                Business name <span className="text-danger">*</span>
              </label>
              <input type="text" className="form-control" {...register("businessName", {required: true})} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="mb-3">
              <label className="form-label">Phone</label>
              <input type="text" className="form-control" {...register("phone", {required: false})} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="mb-3">
              <label className="form-label">
                Client description <span className="text-danger">*</span>
              </label>
              <input type="text" className="form-control" {...register("clientDescription", {required: true})} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="mb-3">
              <label className="form-label">
                Business description <span className="text-danger">*</span>
              </label>
              <input type="text" className="form-control" {...register("businessDescription", {required: true})} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="mb-3">
              <label className="form-label">Number of stores</label>
              <input type="number" className="form-control" {...register("numberOfStores", {required: false})} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="mb-3">
              <label className="form-label">Special instructions</label>
              <input type="text" className="form-control" {...register("specialInstructions", {required: false})} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="mb-3">
              <label className="form-label">Shipping address</label>
              <input type="text" className="form-control" {...register("shippingAddress", {required: false})} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="mb-3">
              <label className="form-label">Billing address</label>
              <input type="text" className="form-control" {...register("billingAddress", {required: false})} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="mb-3">
              <label className="form-label">Web site</label>
              <input type="text" className="form-control" {...register("website", {required: false})} />
            </div>
          </div>
        </div>

        <hr />

        <div className="row">
          <div className="col-md-8">
            <div className="mb-3">
              <label className="form-label">
                Email <span className="text-danger">*</span>
              </label>
              <input type="email" className="form-control" {...register("email", {required: user ? false : true, disabled: user ? true : false})} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="mb-3">
              <label className="form-label">
                Password <span className="text-danger">*</span>
              </label>
              <input type="password" className="form-control" {...register("password", {required: user ? false : true})} />
            </div>
          </div>
          <div className="col-md-8">
            <div className="mb-3">
              <label className="form-label">
                Repassword <span className="text-danger">*</span>
              </label>
              <input
                type="password"
                className="form-control"
                {...register("repassword", {
                  required: user ? false : true,
                  validate: (val) => {
                    if (watch("password") != val) {
                      return "Нууц үг таарахгүй байна."
                    }
                  },
                })}
              />
            </div>
          </div>
        </div>
        <div className="mt-3 d-flex justify-content-between">
          <button type="submit" className="btn btn-dark btn-lg">
            Хадгалах
          </button>
          {user && (
            <button type="button" className="btn btn-danger btn-lg" onClick={() => showModal(`userDeleteModal${user?.id}`)}>
              Устгах
            </button>
          )}
        </div>
      </form>
      {user && (
        <Modal id={`userDeleteModal${user?.id}`}>
          <Modal.Body>
            <SpinLoader loading={deleteLoading} absolute />
            <h4>Устгах уу?</h4>

            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center justify-content-start">
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    DeleteClient({
                      variables: {
                        id: user.id,
                      },
                      onCompleted: (d) => {
                        showModal("")
                        if (d.deleteClient) {
                          toast.success("Устгагдлаа")
                          navigate("/consumers")
                        } else {
                          toast.error("Алдаа гарлаа")
                        }
                      },
                    })
                  }}
                >
                  Устгах
                </button>
                <button className="btn btn-light ms-2" onClick={() => showModal("")}>
                  Болих
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  )
}
