import {gql} from "@apollo/client"

export const ADD_ADMIN = gql`
  mutation AddAdmin($email: String!) {
    AddAdmin(email: $email)
  }
`
export const ADD_CLIENT = gql`
  mutation addClient($email: String!, $password: String!, $firstname: String!, $lastname: String!, $businessName: String!, $phone: String, $clientDescription: String!, $businessDescription: String!, $numberOfStores: String, $specialInstructions: String, $shippingAddress: String, $billingAddress: String, $website: String) {
    addClient(email: $email, password: $password, firstname: $firstname, lastname: $lastname, businessName: $businessName, phone: $phone, clientDescription: $clientDescription, businessDescription: $businessDescription, numberOfStores: $numberOfStores, specialInstructions: $specialInstructions, shippingAddress: $shippingAddress, billingAddress: $billingAddress, website: $website) {
      id
      email
      status
      createdAt
      profile {
        firstname
        lastname
        businessName
        phone
        clientDescription
        businessDescription
        numberOfStores
        specialInstructions
        shippingAddress
        billingAddress
        website
      }
    }
  }
`
export const UPDATE_CLIENT = gql`
  mutation updateClient($firstname: String!, $lastname: String!, $businessName: String!, $phone: String, $clientDescription: String!, $businessDescription: String!, $numberOfStores: String, $specialInstructions: String, $shippingAddress: String, $billingAddress: String, $website: String, $password: String, $id: Int!) {
    updateClient(firstname: $firstname, lastname: $lastname, businessName: $businessName, phone: $phone, clientDescription: $clientDescription, businessDescription: $businessDescription, numberOfStores: $numberOfStores, specialInstructions: $specialInstructions, shippingAddress: $shippingAddress, billingAddress: $billingAddress, website: $website, password: $password, id: $id) {
      id
      email
      status
      createdAt
      profile {
        firstname
        lastname
        businessName
        phone
        clientDescription
        businessDescription
        numberOfStores
        specialInstructions
        shippingAddress
        billingAddress
        website
      }
    }
  }
`
export const DELETE_CLIENT = gql`
  mutation deleteClient($id: Int!) {
    deleteClient(id: $id)
  }
`
export const UPDATE_USER_PRICE = gql`
  mutation updateUserPrice($priceGroupId: Int!, $show_price: Boolean!, $userId: Int!) {
    UpdateUserPrice(priceGroupId: $priceGroupId, show_price: $show_price, userId: $userId)
  }
`
