import {gql} from "@apollo/client"

export const CREATE_PRODUCT = gql`
  mutation CreateProduct($name: String!, $code: String, $gauge: String, $ply: String, $stitch: String, $size: String, $fit: String, $description: String, $variantions: JSON, $thumbnailId: Int) {
    CreateProduct(name: $name, code: $code, gauge: $gauge, ply: $ply, stitch: $stitch, size: $size, fit: $fit, description: $description, variantions: $variantions, thumbnailId: $thumbnailId)
  }
`
export const UPDATE_PRODUCT = gql`
  mutation UpdateProduct($id: Int!, $status: String!, $name: String!, $code: String, $gauge: String, $ply: String, $stitch: String, $size: String, $fit: String, $description: String, $variantions: JSON, $thumbnailId: Int, $related: [Int]) {
    UpdateProduct(id: $id, status: $status, name: $name, code: $code, gauge: $gauge, ply: $ply, stitch: $stitch, size: $size, fit: $fit, description: $description, variantions: $variantions, thumbnailId: $thumbnailId, related: $related)
  }
`
export const DELETE_PRODUCT = gql`
  mutation DeleteProduct($id: Int!) {
    DeleteProduct(id: $id)
  }
`

export const UPDATE_VARIANTION_PRICE = gql`
  mutation UpdateVariantionPrice($data: JSON!) {
    UpdateVariantionPrice(data: $data)
  }
`
