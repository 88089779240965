import React from "react"
import Modal from "../../../components/Modal"
import {useQuery} from "@apollo/client"
import {GET_LOG_LIST} from "../../log/graphql/query"
import useInfiniteScroll from "../../../utils/InfiniteScroll"
import SpinLoader from "../../../components/loaders/SpinLoader"
import LogRow from "../../log/LogRow"

export default function UserLog({user}) {
  const [loading2, setLoading2] = React.useState(false)
  const [list, setList] = React.useState([])
  const [pageInfo, setPageInfo] = React.useState()
  const {loading, fetchMore, refetch} = useQuery(GET_LOG_LIST, {
    fetchPolicy: "network-only",
    variables: {
      userId: user.id,
    },
    onCompleted: (data) => {
      setList(data.LogList.edges.map((edge) => edge.node))

      setPageInfo(data.LogList.pageInfo)
    },
  })
  const onMore = async () => {
    if (pageInfo.hasNextPage) {
      setLoading2(true)
      const moreresult = await fetchMore({
        variables: {
          skip: pageInfo.endCursor,
        },
      })
      setList(list.concat(moreresult.data.LogList.edges.map((edge) => edge.node)))
      setPageInfo(moreresult.data.LogList.pageInfo)
      setLoading2(false)
    }
  }
  const [lastElementRef] = useInfiniteScroll(pageInfo?.hasNextPage ? () => onMore() : () => {}, loading)

  return (
    <Modal log id={`userLogModal${user.id}`}>
      <Modal.Body>
        <div className="table-responsive">
          <table className="table no-wrap user-table mb-0">
            <thead>
              <tr>
                <th scope="col" className="border-0 text-uppercase font-medium ps-2 pe-2">
                  Огноо
                </th>
                <th scope="col" className="border-0 text-uppercase font-medium">
                  Үйлдэл
                </th>
                <th scope="col" className="border-0 text-uppercase font-medium">
                  Тайлбар
                </th>
                <th scope="col" className="border-0 text-uppercase font-medium">
                  Хэрэглэгч
                </th>
                <th scope="col" className="border-0 text-uppercase font-medium">
                  Төрөл
                </th>
                <th scope="col" className="border-0 text-uppercase font-medium">
                  IP хаяг
                </th>
              </tr>
            </thead>
            <tbody>
              {list.map((log, key) => {
                return <LogRow log={log} key={key} />
              })}
            </tbody>
          </table>
        </div>

        {(loading || loading2) && (
          <div className="loader-wrap">
            <SpinLoader loading={true} />
          </div>
        )}
        {pageInfo?.hasNextPage && (
          <>
            <div className="lastDiv" ref={lastElementRef}></div>
          </>
        )}

        {/* <SpinLoader loading={deleteLoading} absolute />
        <h4>Устгах уу?</h4>

        <div className="d-flex align-items-center justify-content-between mt-4">
          <div className="d-flex align-items-center justify-content-start">
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                DeletePost({
                  variables: {
                    id: data.GetPostDetail.id,
                  },
                  onCompleted: () => {
                    toast.success("Устгагдлаа")
                    showModal("")
                    navigate("/posts")
                  },
                })
              }}
            >
              Устгах
            </button>
            <button className="btn btn-light ms-2" onClick={() => showModal("")}>
              Болих
            </button>
          </div>
        </div> */}
      </Modal.Body>
    </Modal>
  )
}
