/* eslint-disable react-hooks/exhaustive-deps */
import {default as React, useEffect, useRef} from "react"
import EditorJS from "@editorjs/editorjs"
import Header from "@editorjs/header"
import Embed from "@editorjs/embed"
import Delimiter from "@editorjs/delimiter"
import List from "@editorjs/list"
import ImageTool from "@editorjs/image"
import Quote from "@editorjs/quote"

import Cookies from "universal-cookie"
import axios from "axios"
import {GetAvatarUrl} from "../../../utils/getAvatarUrl"
const cookies = new Cookies()

const EDITTOR_HOLDER_ID = "editorjs"

const Editor = ({onChangeContent, content}) => {
  const editorInstance = useRef()
  const [editorData, setEditorData] = React.useState(content || "")

  // This will run only once
  useEffect(() => {
    if (!editorInstance.current) {
      initEditor()
    }
    return () => {
      editorInstance.current.destroy()
      editorInstance.current = null
    }
  }, [])

  const initEditor = () => {
    const editor = new EditorJS({
      holder: EDITTOR_HOLDER_ID,

      tools: {
        heading: {
          class: Header,
          config: {
            placeholder: "Дэд гарчиг бичих",
            levels: [2, 3],
            defaultLevel: 2,
          },
        },
        delimiter: Delimiter,
        list: List,
      },
      logLevel: "ERROR",
      data: editorData ? JSON.parse(editorData) : {},
      placeholder: "Энд бичнэ үү!",
      onReady: () => {
        editorInstance.current = editor
      },
      onChange: async (api, event) => {
        editor
          .save()
          .then((outputData) => {
            // console.log("outputData:", outputData)
            onChangeContent(JSON.stringify(outputData))
          })
          .catch((error) => {
            console.log("editor error: ", error)
          })
      },
      autoFocus: false,
    })
  }

  return (
    <React.Fragment>
      <div className="editor-wrap" id={EDITTOR_HOLDER_ID}></div>
    </React.Fragment>
  )
}

export default Editor
