import React, {Fragment} from "react"
import {Link} from "react-router-dom"
import {GetAvatarUrl} from "../../utils/getAvatarUrl"
import {useInit} from "../../providers/Init"

export default function TagsScreen() {
  const {groups} = useInit()
  const [selectedGroupId, setSelectedGroup] = React.useState()

  const [search, setSearch] = React.useState()

  return (
    <div className="">
      <div className="gh-canvas-header">
        <div className="gh-canvas-header-content">
          <div className="flex flex-column">
            <h2 className="gh-canvas-title">
              <a href="/tags" className="active ">
                Таггууд
              </a>
            </h2>
          </div>
          <div className="view-actions">
            <Link to="/tags/add" type="button" className="btn  btn-dark">
              Шинэ таг
            </Link>
          </div>
        </div>
      </div>
      <div className="">
        <div className="filter-box">
          <div className="row row-cols-lg-auto g-2 align-items-center ">
            <div className="col-12">
              <input
                type="search"
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
                className="form-control form-control-sm"
                placeholder="Хайх"
              />
            </div>

            <div className="col-12">
              <select className="form-control" onChange={(e) => setSelectedGroup(e.target.value)}>
                <option value="">Бүх таг</option>
                {groups.map((g, k) => {
                  return (
                    <option value={g.id} key={k}>
                      {g.name}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>
        </div>


        <div className="table-responsive">
          <table className="table no-wrap user-table mb-0">
            <thead>
              <tr>
                <th scope="col" className="border-0 text-uppercase font-medium ps-2 pe-2">
                  Нэр
                </th>
                <th scope="col" className="border-0 text-uppercase font-medium text-end">
                  Засах
                </th>
              </tr>
            </thead>
            <tbody>
              {groups
                ?.filter((f) => (selectedGroupId ? f.id === parseInt(selectedGroupId) : true))
                .map((tg, tgkey) => {
                  return (
                    <Fragment key={tgkey}>
                      {tg.tags
                        ?.filter((f) => (search ? f.name.toLowerCase().includes(search.toLowerCase()) : true))
                        .map((t, tk) => {
                          return (
                            <tr key={tk}>
                              <td className="ps-2">
                                <div className="d-flex align-items-center">
                                  {/* <img src={GetAvatarUrl(tg.thumbnail?.mediaImageGenerateds, "DEFAULT_THUMB")} className="product-list-thumb me-3" alt="" /> */}
                                  <div className="">
                                    <p className="fw-bold mb-0 fs-14">{t.name}</p>
                                    <p className="text-muted mb-0 fs-13">type: {tg.name}</p>
                                  </div>
                                </div>
                              </td>
                              <td className="">
                                <div className="d-flex justify-content-end">
                                  <Link to={`/tags/edit/${t.id}`} className="btn btn-light btn-sm btn-rounded">
                                    Засах
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          )
                        })}
                    </Fragment>
                  )
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
