import { Link } from "react-router-dom";
import GenerateImageScreen from "../modules/settings/GenerateImageScreen";

export default function GenerateImages() {
  return (
    <>
      <GenerateImageScreen />
    </>
  )
}
