import React from "react"
import {createPortal} from "react-dom"
import {useModal} from "../providers/Modal"

const Modal = (props) => {
  const {modalId, showModal} = useModal()

  if (modalId === props.id || props.forceOpen) {
    if (!props.scroll) {
      document.body.classList.add("noscroll")
    }
    let darkClass = ""
    if (props.darkBackdrop) {
      darkClass = "dark"
    }
    let sizeClass = ""
    if (props.medium) {
      sizeClass = "medium"
    }
    if (props.full) {
      sizeClass = "full"
    }
    if (props.log) {
      sizeClass = "log"
    }
    return createPortal(
      <>
        <div className={`modal ${darkClass}`} id={props.id}>
          {modalId && props.locked ? <div className={`modal-backdrop locked`}></div> : <div className={`modal-backdrop`} onClick={() => showModal("")}></div>}

          <div className={`modal-dialog ${sizeClass}`}>
            <div className="modal-content">{props.children}</div>
          </div>
        </div>
      </>,
      document.getElementById("modalRoot")
    )
  }
  return ""
}
function Head({children}) {
  return (
    <div className="modal-head d-flex flex-column">
      <div className="dialog-title-wrap">
        <h1 className="dialog-title">{children}</h1>
      </div>
    </div>
  )
}
function Body({children, className = ""}) {
  return <div className={`modal-body flex-1 ${className}`}>{children}</div>
}
function Foot({children}) {
  return (
    <div className="modal-foot">
      <div className="w-100 d-flex justify-content-end">
        <div className="w-100">{children}</div>
      </div>
    </div>
  )
}

Modal.Head = Head
Modal.Body = Body
Modal.Foot = Foot
export default Modal
