import React from "react"
import {useQuery} from "@apollo/client"
import {GET_TERM_LIST} from "./graphql/query"
import {Link} from "react-router-dom"

export default function TermsScreen() {
  const {loading, fetchMore, data} = useQuery(GET_TERM_LIST, {
    fetchPolicy: "network-only",
  })

  console.log("data:", data)

  return (
    <div className="">
      <div className="table-responsive">
        <table className="table align-middle mb-0 bg-white">
          <thead className="bg-light">
            <tr>
              <th scope="col" className="">
                Нэр
              </th>
              <th scope="col" className=""></th>
            </tr>
          </thead>
          <tbody>
            {data?.GetTermList?.map((item, tk) => {
              return (
                <tr key={tk}>
                  <td className="px-4  w-100">
                    <div className="d-flex align-items-center">
                      <div className="">
                        <p className="fw-bold mb-0 fs-14">{item.title}</p>
                        <p className="text-muted mb-0 fs-13">status: {item.status}</p>
                      </div>
                    </div>
                  </td>
                  <td className="px-4">
                    <Link to={`/settings/term/${item.id}`} className="btn btn-light btn-sm btn-rounded">
                      Засах
                    </Link>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
