import AddPostScreen from "../modules/post/AddPostScreen";

export default function AddPost() {

  return (
    <>
      <AddPostScreen />
    </>
  )
}
