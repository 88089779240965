import {gql} from "@apollo/client"
export const GET_ALL_ORDER_LIST = gql`
  query GetAllOrderList($status: String, $skip: Int, $search: String) {
    GetAllOrderList(status: $status, skip: $skip, search: $search) {
      edges {
        node {
          id
          status
          updatedAt
          createdAt
          userId
          user {
            id
            status
            email
            profile {
              firstname
              lastname
            }
          }
          products {
            id
            createdAt
            productId
            orderId
            userId
            comment
            materialId
            material
            product {
              id
              status
              name
              code
              gauge
              ply
              stitch
              size
              fit
              description
              thumbnail
              thumbnailId
              updatedAt
              updatedById
              createdAt
              createdById
            }
          }
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`
