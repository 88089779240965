import {useMutation} from "@apollo/client"
import React from "react"
import {Link, Navigate} from "react-router-dom"
import SpinLoader from "../../components/loaders/SpinLoader"
import UserForm from "./form/UserForm"
import {ADD_ADMIN} from "./graphql/mutation"
import AdminForm from "./form/AdminForm"
import {toast} from "react-hot-toast"

export default function AddAdminsScreen() {
  const [AddAdmin, {loading, data}] = useMutation(ADD_ADMIN)
  const submitForm = async (values) => {
    await AddAdmin({
      variables: {
        email: values.email,
      },
      onCompleted: (data) => {
        if (!data.AddAdmin) {
          toast.error("Бүртгэлгүй хэрэглэгч байна")
        }
      },
    })
  }

  if (data?.AddAdmin) return <Navigate to={"/admins"} replace />

  return (
    <div className="page-wrapper">
      <div className="gh-canvas-header">
        <div className="gh-canvas-header-content">
          <div className="flex flex-column">
            <div className="gh-canvas-breadcrumb">
              <Link to="/admins">Админ</Link>
              <svg viewBox="0 0 18 27">
                <path
                  d="M2.397 25.426l13.143-11.5-13.143-11.5"
                  strokeWidth="3"
                  stroke="#0B0B0A"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
              Шинэ
            </div>
            <h2 className="gh-canvas-title">
              <a href="#_" className="active ">
                Админ нэмэх
              </a>
            </h2>
          </div>
        </div>
      </div>
      <div className="position-relative">
        <SpinLoader loading={loading} absolute />
        <AdminForm submitForm={submitForm} />
      </div>
    </div>
  )
}
