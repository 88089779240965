import React from "react"
import {useAuth} from "./Auth"
import {InitProvider} from "./Init"
import SpinLoader from "../components/loaders/SpinLoader"

function InitProviderWrap(props) {
  const {isAuthenticated} = useAuth()
  if (!isAuthenticated)
    return (
      <div className="minvh-100 d-flex w-100 align-items-center justify-content-center">
        <div className="pos-rel h-100">
          <SpinLoader loading={true} />
        </div>
      </div>
    )

  return <InitProvider>{props.children}</InitProvider>
}
export default InitProviderWrap
