import React, {createContext, useState} from "react"
import {GET_TAGS, GET_TAG_GROUPS} from "../graphql/query"
import {useQuery} from "@apollo/client"
import SpinLoader from "../components/loaders/SpinLoader"
import {Toaster} from "react-hot-toast"
import {GET_SETTINGS} from "../modules/settings/graphql/query"

export const InitContext = createContext()

function InitProvider(props) {
  const [groups, setGroups] = useState(undefined)
  const [settings, setSettings] = useState(undefined)
  const [tags, setTags] = useState(undefined)

  const {loading: groupsLoading, refetch: refetchGroup} = useQuery(GET_TAG_GROUPS, {
    onCompleted: (data) => {
      setGroups(data.GetTagGroups)
    },
  })
  const {loading: tagsLoading} = useQuery(GET_TAGS, {
    onCompleted: (data) => {
      setTags(data?.GetTags)
    },
  })
  const {loading: settingsLoading} = useQuery(GET_SETTINGS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setSettings(data?.GetSettings)
    },
  })

  if (tagsLoading || groupsLoading || settingsLoading) {
    return (
      <div className="minvh-100 d-flex w-100 align-items-center justify-content-center">
        <div className="pos-rel h-100">
          <SpinLoader loading={true} />
        </div>
      </div>
    )
  }

  return (
    <InitContext.Provider
      value={{
        groups,
        refetchGroup,
        settings,
        tags,
      }}
    >
      {props.children}
      <Toaster position="top-center" reverseOrder={false} />
    </InitContext.Provider>
  )
}

const useInit = () => React.useContext(InitContext)

export {InitProvider, useInit}
