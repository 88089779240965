import {gql} from "@apollo/client"
export const GET_POST_LIST = gql`
  query GetPostList($skip: Int) {
    GetPostList(skip: $skip) {
      edges {
        cursor
        node {
          id
          status
          title
          intro
          excerpt
          content
          thumbnail
          thumbnailId
          updatedAt
          createdAt
          creator
          creatorId
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`
export const GET_POST = gql`
  query GetPostDetail($id: Int!) {
    GetPostDetail(id: $id) {
      id
      status
      title
      intro
      excerpt
      content
      thumbnail
      thumbnailId
      updatedAt
      createdAt
      creator
      creatorId
    }
  }
`
