import {useMutation} from "@apollo/client"
import React from "react"
import {Link, Navigate} from "react-router-dom"
import SpinLoader from "../../components/loaders/SpinLoader"
import {ADD_TAG} from "./graphql/mutation"
import {toast} from "react-hot-toast"
import TagForm from "./form/TagForm"
import {useInit} from "../../providers/Init"

export default function AddTagScreen() {
  const {refetchGroup} = useInit()
  const [AddTag, {loading, data}] = useMutation(ADD_TAG)
  const submitForm = async (values) => {
    values.order = values.order ? parseInt(values.order) : 0
    values.groupId = parseInt(values.groupId)
    if (!values.thumbnailId) {
      delete values.thumbnailId
    }

    await AddTag({
      variables: values,
      onCompleted: (data) => {
        // console.log("data:", data)
        toast.success("Хадгалдлаа")
        refetchGroup()
      },
    })
  }

  if (data?.AddTag) return <Navigate to={"/tags"} replace />

  return (
    <div className="page-wrapper">
      <div className="gh-canvas-header">
        <div className="gh-canvas-header-content">
          <div className="flex flex-column">
            <div className="gh-canvas-breadcrumb">
              <Link to="/tags">Таггууд</Link>
              <svg viewBox="0 0 18 27">
                <path
                  d="M2.397 25.426l13.143-11.5-13.143-11.5"
                  strokeWidth="3"
                  stroke="#0B0B0A"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
              Шинэ
            </div>
            <h2 className="gh-canvas-title">
              <a href="#_" className="active ">
                Таг нэмэх
              </a>
            </h2>
          </div>
        </div>
      </div>
      <div className="position-relative">
        <SpinLoader loading={loading} absolute />
        <TagForm submitForm={submitForm} />
      </div>
    </div>
  )
}
