import React, {useEffect} from "react"
import {useQuery} from "@apollo/client"

import {Link} from "react-router-dom"
import {GetAvatarUrl} from "../../utils/getAvatarUrl"
import {GET_USER_LIST} from "./graphql/query"
import useInfiniteScroll from "../../utils/InfiniteScroll"
import UserRowForm from "./form/UserRowForm"
import SpinLoader from "../../components/loaders/SpinLoader"
import {useInit} from "../../providers/Init"

export default function ConsumersScreen() {
  const {settings} = useInit()
  // filter state
  const [search, setSearch] = React.useState()
  const [currencyId, setCurrencyId] = React.useState()
  const [priceGroupId, setPriceGroupId] = React.useState()
  const [show_price, setShowPrice] = React.useState()

  const [list, setList] = React.useState([])
  const [pageInfo, setPageInfo] = React.useState()
  const [loading2, setLoading2] = React.useState(false)
  const {loading, fetchMore, refetch} = useQuery(GET_USER_LIST, {
    fetchPolicy: "no-cache",
    variables: {},
    onCompleted: (data) => {
      setList(data.UserList.edges.map((edge) => edge.node))
      setPageInfo(data.UserList.pageInfo)
    },
  })
  const onMore = async () => {
    if (pageInfo.hasNextPage) {
      setLoading2(true)
      const moreresult = await fetchMore({
        variables: {
          ...(search && {search: search}),
          ...(currencyId && {currencyId: currencyId}),
          ...(priceGroupId && {priceGroupId: priceGroupId}),
          ...(show_price && {show_price: show_price}),
          skip: pageInfo.endCursor,
        },
      })
      setList(list.concat(moreresult.data.UserList.edges.map((edge) => edge.node)))
      setPageInfo(moreresult.data.UserList.pageInfo)
      setLoading2(false)
    }
  }
  const clearFilter = () => {
    setSearch("")
    setCurrencyId("")
    setPriceGroupId("")
    setShowPrice("")
  }
  const [lastElementRef] = useInfiniteScroll(pageInfo?.hasNextPage ? () => onMore() : () => {}, loading)

  const refetchMore = () => {
    refetch({
      search: search,
      currencyId: currencyId,
      priceGroupId: priceGroupId || undefined,
      show_price: show_price,
    })
  }

  useEffect(() => {
    refetchMore()

    return () => {}
  }, [search, currencyId, priceGroupId, show_price])

  return (
    <div className="page-wrapper">
      <div className="gh-canvas-header">
        <div className="gh-canvas-header-content">
          <div className="flex flex-column">
            <h2 className="gh-canvas-title">
              <a href="/admins" className="active ">
                Хэрэглэгчид
              </a>
            </h2>
          </div>
          <div className="view-actions">
            <Link to="/consumers/add" type="button" className="btn  btn-dark">
              Хэрэглэгч нэмэх
            </Link>
          </div>
        </div>
      </div>

      <div className="">
        <div className="filter-box">
          <div className="row row-cols-lg-auto g-2 align-items-center mb-2">
            <div className="col-12">
              <input
                type="search"
                // defaultValue={search}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className="form-control form-control-sm"
                placeholder="Хайх"
              />
            </div>

            <div className="col-12">
              <select value={currencyId} className="form-select" onChange={(e) => setCurrencyId(e.target.value)}>
                <option value={""}>Валют</option>
                {settings?.currency.map((c, k) => (
                  <option key={k} value={c.id}>
                    {c.id}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-12">
              <select value={priceGroupId} className="form-select" onChange={(e) => setPriceGroupId(e.target.value ? parseInt(e.target.value) : undefined)}>
                <option value={""}>Үнэ зэрэглэл...</option>
                {settings.price_group.map((pg, k) => (
                  <option key={k} value={pg.id}>
                    {pg.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12">
              <select value={show_price} className="form-select" onChange={(e) => setShowPrice(e.target.value)}>
                <option value={""}>Үнэ харагдах...</option>
                <option value="show">Үнэ хардаг</option>
                <option value="hide">Үнэ хардаггүй</option>
              </select>
            </div>

            {(search || currencyId || priceGroupId || show_price) && (
              <>
                <div className="col-12">
                  <button className="btn btn-secondary" onClick={clearFilter}>
                    Арилгах
                  </button>
                </div>
              </>
            )}
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="row">
                <div className="col"></div>
              </div>
            </div>
          </div>

          {pageInfo && (
            <div className="records">
              Үр дүн: <b>{list.length}</b>/<b>{pageInfo.count}</b>
            </div>
          )}
        </div>

        <div className="table-responsive">
          <table className="table no-wrap user-table mb-0">
            <thead>
              <tr>
                <th scope="col" style={{width: "30px"}} className="border-0 text-uppercase font-medium ps-2 pe-2">
                  #
                </th>
                <th scope="col" className="border-0 text-uppercase font-medium">
                  Хэрэглэгч
                </th>
                <th scope="col" className="border-0 text-uppercase font-medium">
                  Бизнес
                </th>
                <th scope="col" className="border-0 text-uppercase font-medium">
                  Холбоо барих
                </th>
                <th scope="col" className="border-0 text-uppercase font-medium">
                  Үнэ тохиргоо
                </th>
                <th scope="col" className="border-0 text-uppercase font-medium">
                  Тайлбар
                </th>
                <th scope="col" className="border-0 text-uppercase font-medium">
                  Бүртгүүлсэн
                </th>
                <th scope="col" className="border-0 text-uppercase font-medium text-end">
                  Засах
                </th>
              </tr>
            </thead>
            <tbody>
              {list.map((user, key) => {
                return <UserRowForm user={user} key={key} count={key + 1} />
              })}
            </tbody>
          </table>
        </div>

        {(loading || loading2) && (
          <div className="loader-wrap">
            <SpinLoader loading={true} />
          </div>
        )}
        {pageInfo?.hasNextPage && (
          <>
            <div className="lastDiv" ref={lastElementRef}></div>
          </>
        )}
      </div>
    </div>
  )
}
